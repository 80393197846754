import { finishActivity } from "utils/app-bridge";

export const isMobile = (): boolean => {
	if (typeof window !== "undefined") {
		return window.innerWidth <= 768; // 768px is a common mobile breakpoint
	}
	return false; // Default to desktop if window is undefined (server-side rendering)
};

export const getStringFromSessionStorage = (key: string): string | null => {
	if (typeof window !== "undefined") {
		const value = sessionStorage.getItem(key);
		return value ? value : null;
	}
	return null; // Return null if window is undefined (server-side rendering)
};

export function handleBackPressListener(currentHandlers = []) {
	const currentPath = window.location.pathname;

	for (let { condition, action } of currentHandlers) {
		// If the condition is met, execute the corresponding action

		if (condition()) {
			action();
			return true; // Stop after the first matching condition
		}
	}

	if (currentPath === "/learn/degree") {
		finishActivity();
		return;
	}

	window.history.back();
}
