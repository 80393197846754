export const KEY_MOBILE_NUMBER = "mobile_number";
export const KEY_OTP_SENT_AT = "otp_at";

export const OTP_LENGTH = 4;

/** Allow user to send OTP after this time much time has elapsed */
export const OTP_RESEND_TIMEOUT = 30; // In seconds

/** Block user from verifying OTP after these many attempts until {OTP_REATTEMPT_TIMEOUT} */
export const ALLOWED_OTP_VERIFY_ATTEMPTS = 3; // In seconds

/**  Allow user to re-attempt OTP verification after this timeout */
export const OTP_REATTEMPT_TIMEOUT = 30; // In seconds

export const USER_AUTH = {
    NUMBER_SCREEN: "phone_number",
    OTP_SCREEN: "otp_verification",
    ALL_DETAILS: "all_details"
};

export const KEY_USER_ID = "user_id";

export const AUTH_SOURCE = {
    JOB_APPLY: "JOB_APPLY"
}