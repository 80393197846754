/* eslint-disable react/jsx-props-no-spreading */
const GoldBadge = (props) => (
	<svg width={104} height={32} viewBox="0 0 104 32" {...props}>
		<g fill="none" fillRule="evenodd">
			<path
				fill="#FC9F5B"
				d="M24.692 4h48.616c2.675 0 3.645.278 4.623.801a5.456 5.456 0 012.268 2.268c.523.978.801 1.948.801 4.623v8.616c0 2.675-.278 3.645-.801 4.623a5.456 5.456 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H24.692c-2.675 0-3.645-.278-4.623-.801a5.456 5.456 0 01-2.268-2.268c-.523-.978-.801-1.948-.801-4.623v-8.616c0-2.675.278-3.645.801-4.623a5.456 5.456 0 012.268-2.268C21.047 4.278 22.017 4 24.692 4z"
			/>
			<path
				fill="#FFF"
				fillRule="nonzero"
				d="M45.212 20.204c.52 0 1.04-.076 1.56-.228.52-.152.996-.368 1.428-.648v-4.116h-3.18l.192 1.392h1.08v1.884a2.31 2.31 0 01-1.128.276c-.592 0-1.028-.222-1.308-.666-.28-.444-.42-1.198-.42-2.262 0-.984.184-1.718.552-2.202.368-.484.86-.726 1.476-.726.328 0 .618.05.87.15.252.1.526.262.822.486l.996-1.044c-.776-.68-1.7-1.02-2.772-1.02-.752 0-1.43.17-2.034.51-.604.34-1.08.838-1.428 1.494-.348.656-.522 1.44-.522 2.352 0 1.432.32 2.518.96 3.258.64.74 1.592 1.11 2.856 1.11zm8.168 0c.776 0 1.446-.174 2.01-.522.564-.348.996-.85 1.296-1.506.3-.656.45-1.436.45-2.34 0-1.384-.33-2.456-.99-3.216-.66-.76-1.582-1.14-2.766-1.14-.776 0-1.444.174-2.004.522-.56.348-.99.85-1.29 1.506-.3.656-.45 1.432-.45 2.328 0 .92.15 1.706.45 2.358.3.652.73 1.15 1.29 1.494.56.344 1.228.516 2.004.516zm0-1.44c-.56 0-.98-.232-1.26-.696-.28-.464-.42-1.208-.42-2.232 0-1.024.138-1.766.414-2.226.276-.46.698-.69 1.266-.69.568 0 .992.228 1.272.684.28.456.42 1.2.42 2.232 0 1.032-.138 1.778-.414 2.238-.276.46-.702.69-1.278.69zM63.816 20l.204-1.56h-3.24v-6.756h-1.968V20h5.004zm4.148 0c1.224 0 2.22-.314 2.988-.942.768-.628 1.152-1.714 1.152-3.258 0-1.568-.394-2.646-1.182-3.234-.788-.588-1.834-.882-3.138-.882h-2.316V20h2.496zm.12-1.428h-.648v-5.46h.624c.424 0 .78.074 1.068.222.288.148.516.418.684.81.168.392.252.944.252 1.656 0 1.04-.168 1.762-.504 2.166-.336.404-.828.606-1.476.606z"
			/>
			<g
				fill="#FFF"
				stroke="#FFC295"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
				<path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
				<path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
			</g>
			<g
				fill="#FFF"
				stroke="#FC9F5B"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
				<path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
				<path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
			</g>
			<path
				fill="#FC9F5B"
				d="M88.131 11.131L91 10l-2.869-1.131L87 6l-1.131 2.869L83 10l2.869 1.131L87 14zm0 12L91 22l-2.869-1.131L87 18l-1.131 2.869L83 22l2.869 1.131L87 26zm9.566-5.434L102 16l-4.303-1.697L96 10l-1.697 4.303L90 16l4.303 1.697L96 22z"
			/>
		</g>
	</svg>
);

export default GoldBadge;
