import * as React from "react";
const NewTagComponent = (props) => (
	<svg
		width={28}
		height={14}
		viewBox="0 0 28 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect x={0.500061} y={0.5} width={27} height={13} rx={6.5} fill="white" />
		<rect
			x={0.500061}
			y={0.5}
			width={27}
			height={13}
			rx={6.5}
			stroke="url(#paint0_linear_626_14953)"
		/>
		<path
			d="M9.37222 4.18182V10H8.30972L5.77847 6.33807H5.73586V10H4.50574V4.18182H5.58529L8.09665 7.84091H8.14779V4.18182H9.37222ZM10.3886 10V4.18182H14.309V5.19602H11.6187V6.58239H14.1073V7.59659H11.6187V8.9858H14.3204V10H10.3886ZM16.5967 10L14.9319 4.18182H16.2756L17.2387 8.22443H17.287L18.3495 4.18182H19.5001L20.5597 8.23295H20.6109L21.5739 4.18182H22.9177L21.2529 10H20.054L18.9461 6.19602H18.9006L17.7955 10H16.5967Z"
			fill="url(#paint1_linear_626_14953)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_626_14953"
				x1={-9.04419}
				y1={4.52212}
				x2={-3.24035}
				y2={22.4902}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FF8800" />
				<stop offset={1} stopColor="#E63535" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_626_14953"
				x1={-2.46012}
				y1={5.23009}
				x2={1.68548}
				y2={18.0644}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FF8800" />
				<stop offset={1} stopColor="#E63535" />
			</linearGradient>
		</defs>
	</svg>
);
export default NewTagComponent;
