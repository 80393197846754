import { useEffect, useState } from "react";
import { debounce } from "lodash";

export const MOBILE_BREAKPOINT = "768px";

const useIsDesktop = (): boolean => {
	const [isDesktop, setIsDesktop] = useState<boolean>(
		typeof window !== "undefined"
			? window.matchMedia(`(min-width: ${MOBILE_BREAKPOINT})`).matches
			: true
	);

	useEffect(() => {
		/**
		 * Attach a debounced handler that matched the media width everytime
		 * window resize happens.
		 */
		const handleResize = () => {
			setIsDesktop(window.matchMedia(`(min-width: ${MOBILE_BREAKPOINT})`).matches);
		};
		const debouncedHandleResize = debounce(handleResize, 250);
		window.addEventListener("resize", debouncedHandleResize);
		/**
		 * Unattach listener upon unmounting so as to avoid memory leaks.
		 */
		return () => window.removeEventListener("resize", debouncedHandleResize);
	}, []);

	return isDesktop;
};

export default useIsDesktop;
