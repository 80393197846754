/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const CloseIcon20 = (props) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_2011_99172)">
			<path
				d="M15.7338 4.27531C15.3788 3.92036 14.8055 3.92036 14.4505 4.27531L10 8.71672L5.54949 4.26621C5.19454 3.91126 4.62116 3.91126 4.26621 4.26621C3.91126 4.62116 3.91126 5.19454 4.26621 5.54949L8.71672 10L4.26621 14.4505C3.91126 14.8055 3.91126 15.3788 4.26621 15.7338C4.62116 16.0887 5.19454 16.0887 5.54949 15.7338L10 11.2833L14.4505 15.7338C14.8055 16.0887 15.3788 16.0887 15.7338 15.7338C16.0887 15.3788 16.0887 14.8055 15.7338 14.4505L11.2833 10L15.7338 5.54949C16.0796 5.20364 16.0796 4.62116 15.7338 4.27531Z"
				fill={props.fill || "white"}
			/>
		</g>
		<defs>
			<clipPath id="clip0_2011_99172">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default CloseIcon20;
