import * as React from "react";

const LanguageIcon = (props) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#4D3951"
			d="M1.394 4.746c.326-.236.745-.417 1.259-.54A5.893 5.893 0 0 1 4.173 4c.49 0 .952.062 1.39.186.451.125.84.317 1.165.578.338.249.607.566.807.951.2.373.3.82.3 1.342 0 .56-.13 1.038-.393 1.436-.263.385-.551.677-.864.876.25.074.52.13.807.168.3.037.595.055.883.055.4 0 .776-.037 1.127-.111.363-.087.638-.2.826-.336v-3.43H8.869V4.149h4.977v1.566h-1.597v10.29h-2.028v-5.014c-.3.149-.739.223-1.315.223-.25 0-.488-.018-.713-.056a5.73 5.73 0 0 1-.714-.223l-.037.037c.413.447.62 1 .62 1.66 0 .993-.345 1.764-1.034 2.31-.688.535-1.62.802-2.798.802a6.12 6.12 0 0 1-1.86-.28c-.588-.186-1.044-.416-1.37-.69l.676-1.397a4.04 4.04 0 0 0 1.014.559c.376.124.801.186 1.277.186.576 0 1.058-.143 1.446-.428.388-.299.582-.74.582-1.324 0-.56-.181-1.007-.544-1.342-.363-.336-.908-.503-1.634-.503H2.54V8.94h1.07c.639.012 1.159-.13 1.56-.429.4-.31.6-.74.6-1.286 0-.534-.181-.932-.544-1.193-.364-.273-.796-.41-1.296-.41-.313 0-.639.043-.977.13a2.65 2.65 0 0 0-.92.41l-.639-1.416ZM15.657 15.414h3.287l-.62-1.733a95.822 95.822 0 0 0-.507-1.473c-.15-.46-.307-.963-.47-1.51h-.037c-.176.51-.345 1.006-.507 1.491-.163.472-.338.97-.526 1.492l-.62 1.733Zm.563-7.158h2.292L23 20h-2.423l-1.051-2.89h-4.414L14.061 20h-2.385L16.22 8.256Z"
		/>
	</svg>
);
export default LanguageIcon;
