import * as React from "react";

const LocationIconOutline = (props) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#4D3951"
			d="M12 6.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM12 2a7 7 0 0 1 7 7c0 5.25-7 13-7 13S5 14.25 5 9a7 7 0 0 1 7-7Zm0 2a5 5 0 0 0-5 5c0 1 0 3 5 9.71C17 12 17 10 17 9a5 5 0 0 0-5-5Z"
		/>
	</svg>
);
export default LocationIconOutline;
