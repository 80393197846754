import mixpanel from "mixpanel-browser";
import isBot from "isbot";
import { USER_PLATFORM } from "constants/tracking";
import { URL_PARAMS, URL_PARAM_KEYS } from "constants/utmTracking/utmTracking";
import config from "../config";
import source from "./source";
import {
	detectDeviceOs,
	detectDeviceType,
	getUserId,
	isHostLaunchedMicrosite,
	isOnboardingComplete,
	isUserLoggedIn,
} from ".";
import { MICROSITES, SESSION_STORAGE_KEYS } from "./constants";

// eslint-disable-next-line no-unused-expressions
mixpanel.init(config.mixpanelKey);

/**
 * We will be caching the UTM query parameters so that we don't have to
 * fetch from session storage for each event emission.
 */
const UTM_PARAMS_CACHE = {};

function transformValuesToStrings(input) {
	if (Array.isArray(input)) {
		return input.map((item) => JSON.stringify(item));
	}
	if (typeof input === "object" && input !== null) {
		const transformed = {};
		// eslint-disable-next-line no-restricted-syntax
		for (const key in input) {
			if (Array.isArray(input[key])) {
				transformed[key] = input[key].map(JSON.stringify);
			} else if (typeof input[key] === "object") {
				transformed[key] = JSON.stringify(input[key]);
			} else {
				transformed[key] = input[key];
			}
		}
		return transformed;
	}
	return input;
}

const actions = {
	identify: (id) => {
		mixpanel.identify(id);
	},
	alias: (id) => {
		mixpanel.alias(id);
	},
	track: (name, props) => {
		if (typeof window === "undefined") return;
		const isViUser =
			typeof window !== "undefined"
				? window?.location.pathname?.includes("microsite/vi") ||
				  isHostLaunchedMicrosite() === MICROSITES.VI
				: false;

		const vendor = isViUser ? "vi" : "apna";
		// Handle UTM paramters for MP events
		const query = {};
		/**
		 * Populate cache and MP identifier for the UTM parameters
		 */
		if (UTM_PARAMS_CACHE.utm_source) {
			query["UTM Source"] = UTM_PARAMS_CACHE.utm_source;
		} else {
			const utmSource = sessionStorage.getItem(SESSION_STORAGE_KEYS.UTM_SOURCE);
			if (utmSource) {
				query["UTM Source"] = utmSource;
				UTM_PARAMS_CACHE.utm_source = utmSource;
			}
		}

		if (UTM_PARAMS_CACHE.utm_medium) {
			query["UTM Medium"] = UTM_PARAMS_CACHE.utm_medium;
		} else {
			const utmMedium = sessionStorage.getItem(SESSION_STORAGE_KEYS.UTM_MEDIUM);
			if (utmMedium) {
				query["UTM Medium"] = utmMedium;
				UTM_PARAMS_CACHE.utm_medium = utmMedium;
			}
		}

		if (UTM_PARAMS_CACHE.utm_campaign) {
			query["UTM Campaign"] = UTM_PARAMS_CACHE.utm_campaign;
		} else {
			const utmCampaign = sessionStorage.getItem(SESSION_STORAGE_KEYS.UTM_CAMPAIGN);
			if (utmCampaign) {
				query["UTM Campaign"] = utmCampaign;
				UTM_PARAMS_CACHE.utm_campaign = utmCampaign;
			}
		}

		if (UTM_PARAMS_CACHE.utm_id) {
			query["UTM Id"] = UTM_PARAMS_CACHE.utm_id;
		} else {
			const utmId = sessionStorage.getItem(SESSION_STORAGE_KEYS.UTM_ID);
			if (utmId) {
				query["UTM Id"] = utmId;
				UTM_PARAMS_CACHE.utm_id = utmId;
			}
		}

		if (UTM_PARAMS_CACHE.utm_term) {
			query["UTM Term"] = UTM_PARAMS_CACHE.utm_term;
		} else {
			const utmTerm = sessionStorage.getItem(SESSION_STORAGE_KEYS.UTM_TERM);
			if (utmTerm) {
				query["UTM Term"] = utmTerm;
				UTM_PARAMS_CACHE.utm_term = utmTerm;
			}
		}

		if (UTM_PARAMS_CACHE.utm_content) {
			query["UTM Content"] = UTM_PARAMS_CACHE.utm_content;
		} else {
			const utmContent = sessionStorage.getItem(SESSION_STORAGE_KEYS.UTM_CONTENT);
			if (utmContent) {
				query["UTM Content"] = utmContent;
				UTM_PARAMS_CACHE.utm_content = utmContent;
			}
		}

		mixpanel.track(name, {
			...props,
			trafficChannel: source(),
			isCrawler: isBot(window?.navigator?.userAgent),
			userAgent: window?.navigator?.userAgent,
			deviceOS: detectDeviceOs(),
			deviceType: detectDeviceType(),
			userPlatform: USER_PLATFORM[vendor],
			web_logged_in: isUserLoggedIn() ? "Yes" : "No",
			profile_enriched: isUserLoggedIn() ? isOnboardingComplete() : undefined,
			userUID: isUserLoggedIn() ? getUserId() : "N/A",
			...query,
		});
	},
	trackWithoutGenricProps: (name, props) => {
		mixpanel.track(name, {
			...props,
		});
	},
	people: {
		set: (props) => {
			mixpanel.people.set_once(props);
		},
	},
	reset: () => {
		mixpanel.reset();
	},
};

const Mixpanel = actions;

export default Mixpanel;
