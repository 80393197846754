export type Nullable<T> = T | null;
interface NBLocationType {
    id: string,
    name: string,
    is_live?: Boolean | null,
    lat?: number | null,
    lng?: number | null,
}

export interface NBGPSType {
    latitude?: number | null,
    longitude?: number | null,
}

export interface UserLocation {
    display_name?: string | null,
    area?: NBLocationType | null,
    city?: NBLocationType | null,
    district?: NBLocationType | null,
    sub_district?: NBLocationType | null, 
    hometown?: NBLocationType | null,
    accurate_location?: NBGPSType | null,
    derived_location?: NBGPSType | null,
    pincode?: string | null,
    source: string, // APNA or NB
}

export interface LocationType {
    id: string;
    type?: string;
    name: string;
    city?: string;
    state: string;
    country: string;
    cluster?: string | null;
    is_live?: boolean | null;
}

export interface NearestLocationResponseType {
    city: LocationType;
    subdistrict?: LocationType | null;
    area?: LocationType | null;
    display_name?: string | null;
}

export interface LocationIds {
    [key: string]: string | null | undefined;
    area_id?: string | null,
    city_id?: string | null,
    sub_district_id?: string | null,
    hometown_id?: string | null,
    cluster_id?: string | null, 
}

interface NBCity {
    status: number,
    name?: string | null | undefined,
}

interface NBArea {
    name: string,
}

interface NBSubDistrict {
    name: string,
}

export interface NBLocationSearchData {
    name: string,
    location_ids: LocationIds,
    city?: NBCity | null,
    area?: NBArea | null,
    sub_district?: NBSubDistrict | null
}

export interface NBLocationDetails {
    location_ids: LocationIds,
    cityStatus?: number,
    displayName: string,
    cityName?: string,
    areaName?: string,
    subDistrictName?: string
}

export enum FurtherEducationType {
    GRADUATE = "Graduate Degree",
    POST_GRADUATE = "Post Graduate Degree",
    CERTIFICATION = "Certification / Courses",
    GOVERNMENT_JOB_PREPARATION = "Govt. Job Preparation",
    NOT_INTERESTED = "Not Interested"
}

export enum FurtherEducationMode {
    ONLINE = "Online (India)",
    OFFLINE = "On-Campus (India)",
    ABROAD = "Study abroad"
}

export type HigherEducationDegreeType = {
    higherEducationDegreeID: Nullable<string>;
    higherEducationDegreeName: Nullable<string>;
}