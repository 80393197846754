import * as React from "react";

const SearchIconOutline = ({ fill = "#4D3951" }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
		<path
			fill={fill}
			d="M16.665 14.898h-.927l-.328-.317a7.618 7.618 0 0 0 1.736-6.26c-.551-3.26-3.273-5.863-6.558-6.262-4.962-.61-9.139 3.565-8.529 8.524.4 3.283 3.004 6.003 6.265 6.554a7.629 7.629 0 0 0 6.265-1.735l.316.328v.927l4.986 4.983a1.24 1.24 0 0 0 1.748 0c.481-.481.481-1.267 0-1.748l-4.974-4.994Zm-7.039 0a5.27 5.27 0 0 1-5.279-5.276 5.27 5.27 0 0 1 5.28-5.276 5.27 5.27 0 0 1 5.278 5.276 5.27 5.27 0 0 1-5.279 5.276Z"
		/>
	</svg>
);
export default SearchIconOutline;
