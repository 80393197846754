/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const FilterIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<rect
			width={2}
			height={18}
			x={21}
			y={5}
			fill="#4D3951"
			rx={1}
			transform="rotate(90 21 5)"
		/>
		<rect
			width={2}
			height={18}
			x={21}
			y={11}
			fill="#4D3951"
			rx={1}
			transform="rotate(90 21 11)"
		/>
		<rect
			width={2}
			height={18}
			x={21}
			y={17}
			fill="#4D3951"
			rx={1}
			transform="rotate(90 21 17)"
		/>
		<circle cx={12} cy={18} r={2} fill="#4D3951" transform="rotate(90 12 18)" />
		<circle cx={9} cy={6} r={2} fill="#4D3951" transform="rotate(90 9 6)" />
		<circle cx={15} cy={12} r={2} fill="#4D3951" transform="rotate(90 15 12)" />
	</svg>
);
export default FilterIcon;
