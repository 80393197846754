export const GTAG_EVENTS = {
	HomePageVisit: "HomePageVisit",
	CandidateButtonClick: "CandidateButtonClick",
	EmployerButtonClick: "EmployerButtonClick",
	NumberSubmission: "NumberSubmission",
	GetOtp: "GetOtp",
	VerifyOtp: "VerifyOtp",
	JobApply: "JobApply",
};
const GTAG_SEND_TO_MAP = {
	[GTAG_EVENTS.HomePageVisit]: "DC-11888069/invmedia/apna_0+standard",
	[GTAG_EVENTS.CandidateButtonClick]: "DC-11888069/invmedia/apna_00+standard",
	[GTAG_EVENTS.NumberSubmission]: "DC-11888069/invmedia/apna_000+standard",
	[GTAG_EVENTS.GetOtp]: "DC-11888069/invmedia/apna_001+standard",
	[GTAG_EVENTS.VerifyOtp]: "DC-11888069/invmedia/apna_002+standard",
	[GTAG_EVENTS.EmployerButtonClick]: "DC-11888069/invmedia/apna_008+standard",
	[GTAG_EVENTS.JobApply]: "DC-11888069/invmedia/apna_007+standard",
};

export const GTag = (action) => {
	try {
		if (typeof window !== "undefined") {
			window.dataLayer = window.dataLayer || [];
			function gtag(...args) {
				window.dataLayer.push(args);
			}
			gtag("event", "conversion", {
				allow_custom_scripts: true,
				send_to: GTAG_SEND_TO_MAP[action],
			});
		}
	} catch (err) {
		throw new Error(err);
	}
};
