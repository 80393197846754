/* eslint-disable import/prefer-default-export */
import QueriesKeys from "constants/queryKeys";
import { useQuery } from "react-query";
import { getCandidateDetails } from "utils/apiClientPrivate";
import { candidateDetailsParser } from "../utils/parsers";

export const useCandidateDetails = (phoneNumber, isHeaderDetailsRequired = false) =>
	useQuery<unknown>(
		[QueriesKeys.GET_CANDIDATE_DETAILS, phoneNumber],
		async () => {
			const { data } = await getCandidateDetails();
			return isHeaderDetailsRequired
				? {
						name: data?.full_name,
						photoURL:
							data?.photo?.source !== "auto_generated" && data?.photo?.path,
				  }
				: candidateDetailsParser(data, { phoneNumber });
		},
		{
			enabled: isHeaderDetailsRequired ? true : !!phoneNumber,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);
