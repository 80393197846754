import * as React from "react";

const ProfileIcon = (props) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#4D3951"
			fillRule="evenodd"
			d="M12 12a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm3-5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM12 14c-5.4 0-8 3.37-8 5.333V20.4A1.6 1.6 0 0 0 5.6 22h12.8a1.6 1.6 0 0 0 1.6-1.6v-1.067C20 17.37 17.4 14 12 14Zm-6 5.333c0-.206.225-1.02 1.244-1.857C8.188 16.701 9.731 16 12 16s3.813.701 4.756 1.476c1.019.837 1.244 1.65 1.244 1.857V20H6v-.667Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default ProfileIcon;
