export default {
	development: {
		env: "development",
		baseUrl: "https://backend-stg.apna.co",
		websiteUrl: "https://apna-co.staging.infra.apna.co/",
		dashboardUrl: "https://employer-test.apna.co",
		infra_base_url: "https://api.staging.infra.apna.co",
		infra_base_url_private: "http://api-private.staging.infra.apna.co",
		firebase: {
			apiKey: "AIzaSyCR-DCbkt3Ut2wVFDNQhx7Smf7KlNDJPjk",
			authDomain: "apna-android-debug.firebaseapp.com",
			databaseURL: "https://apna-android-debug.firebaseio.com",
			projectId: "apna-android-debug",
			storageBucket: "mumbai_apnatime_stag",
			messagingSenderId: "378198512148",
			appId: "1:378198512148:web:c08022d6194c5246d80d01",
			measurementId: "G-0DMM0K4F69",
			profilePhotoStorage: "profile_photo_stg",
		},
		google: {
			analytics: "UA-155377963-1",
			tagManager: "GTM-5CQ5729",
		},
		mixpanelKey: "6be41536d5701ebe5974cad71e5d0ec4",
		// mixpanelKey: "e30bbfeeabf8702428be61e7b718549e", // Android App - Debug
		intercom: "d0yv8lrt",
		sentry: "https://9606d2cc1dac4238b899f747d3165c41@sentry.io/5188582",
		// TODO: Revert after UAT
		branchIO: "key_test_cpPtPeH1YBRDuRUCQ17CXcijtEkVtGTU",
		facebook: "685106322303561", // vibhor.sharma94@gmail.com - "apna web" project in FB Developers
		gumletBaseUrl: "https://apna-staging.gumlet.io",
		// gumletBaseUrl: "https://cdn.apna.co",
		slackHooks: {
			contactForm:
				"https://hooks.slack.com/workflows/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",
		},
		datadogEnv: "staging",
		unleashProxyUrl: "https://unleash-proxy.infra.apna.co/proxy",
		unleashAuthKey: "unleashEVVZuaqq",
		cleverTapAccountId: "TEST-967-8R9-WW6Z",
		oauthBaseUrl: "https://api.staging.infra.apna.co",
		applyNowBranchBaseUrl: "https://apna-job.test-app.link/apply-now",
	},
	test: {
		env: "staging",
		baseUrl: "https://backend-stg.apna.co",
		websiteUrl: "https://apna-co.staging.infra.apna.co/",
		dashboardUrl: "https://employer-test.apna.co",
		infra_base_url: "https://api.staging.infra.apna.co",
		infra_base_url_private: "http://api-private.staging.infra.apna.co",
		firebase: {
			apiKey: "AIzaSyCR-DCbkt3Ut2wVFDNQhx7Smf7KlNDJPjk",
			authDomain: "apna-android-debug.firebaseapp.com",
			databaseURL: "https://apna-android-debug.firebaseio.com",
			projectId: "apna-android-debug",
			storageBucket: "mumbai_apnatime_stag",
			messagingSenderId: "378198512148",
			appId: "1:378198512148:web:c08022d6194c5246d80d01",
			measurementId: "G-0DMM0K4F69",
			profilePhotoStorage: "profile_photo_stg",
		},
		google: {
			analytics: "UA-155377963-1",
			tagManager: "GTM-5CQ5729",
		},
		mixpanelKey: "6be41536d5701ebe5974cad71e5d0ec4",
		// mixpanelKey: "e30bbfeeabf8702428be61e7b718549e", // Android App - Debug
		intercom: "d0yv8lrt",
		sentry: "https://9606d2cc1dac4238b899f747d3165c41@sentry.io/5188582",
		// TODO: Revert after UAT
		branchIO: "key_test_cpPtPeH1YBRDuRUCQ17CXcijtEkVtGTU",
		facebook: "685106322303561", // vibhor.sharma94@gmail.com - "apna web" project in FB Developers
		gumletBaseUrl: "https://apna-staging.gumlet.io",
		// gumletBaseUrl: "https://cdn.apna.co",
		slackHooks: {
			contactForm:
				"https://hooks.slack.com/workflows/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",
		},
		datadogEnv: "staging",
		unleashProxyUrl: "https://unleash-proxy.infra.apna.co/proxy",
		unleashAuthKey: "unleashEVVZuaqq",
		cleverTapAccountId: "TEST-967-8R9-WW6Z",
		oauthBaseUrl: "https://api.staging.infra.apna.co",
		applyNowBranchBaseUrl: "https://apna-job.test-app.link/apply-now",
	},
	production: {
		env: "production",
		baseUrl: "https://production.apna.co",
		infra_base_url: "https://api.production.infra.apna.co",
		infra_base_url_private: "http://api-private.production.infra.apna.co",
		websiteUrl: "https://apna.co/",
		dashboardUrl: "https://employer.apna.co",
		firebase: {
			apiKey: "AIzaSyCPwwuCUtx0mBOKHOzEkayMJt4Wm8Wg61s",
			authDomain: "apnatime-fbc72.firebaseapp.com",
			databaseURL: "https://apnatime-fbc72.firebaseio.com",
			projectId: "apnatime-fbc72",
			storageBucket: "mumbai_apnatime_prod",
			profilePhotoStorage: "profile_photo",
			messagingSenderId: "978462882991",
			appId: "1:978462882991:web:dd8e8f71975db46b4b685a",
			measurementId: "G-Z9W20H2DTD",
		},
		google: {
			analytics: "UA-155377963-1",
			tagManager: "GTM-K4X8XNN",
		},
		// mixpanelKey: "3e17beda8f105f1d9012c7632f117dd0",
		mixpanelKey: "b96e1914cc08adb5d3332330e947ca74", // Candidate Android App
		intercom: "y0p3z303",
		sentry: "https://9606d2cc1dac4238b899f747d3165c41@sentry.io/5188582",
		branchIO: "key_live_daMARmQZ1yKByHRqT9TNIiiiwrkTAIJy",
		facebook: "685106322303561", // vibhor.sharma94@gmail.com - "apna web" project in FB Developers
		gumletBaseUrl: "https://cdn.apna.co",
		slackHooks: {
			contactForm:
				"https://hooks.slack.com/workflows/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",
		},
		datadogEnv: "production",
		// replace with prod keys
		unleashProxyUrl: "https://unleash-proxy.production.infra.apna.co/proxy/",
		unleashAuthKey: "unleashEVVZuaqq",
		cleverTapAccountId: "867-8R9-WW6Z",
		oauthBaseUrl: "https://api.production.infra.apna.co",
		applyNowBranchBaseUrl: "https://l.apna.co/apply-now",
	},
};
