export const JOB_FILTERS = [
	{ name: "All", key: "all", slug: "", filterQuery: "" },
	{
		key: "wfh",
		name: "Work from home",
		slug: "work_from_home",
		filterQuery: "wfh=1",
	},
	{
		key: "partTime",
		name: "Part time",
		slug: "part_time",
		filterQuery: "part_time=1",
	},
	{
		key: "nightShift",
		name: "Night Shift",
		slug: "night_shift",
		filterQuery: "shift=night",
	},
	{
		key: "female",
		name: "Jobs for women",
		slug: "female",
		filterQuery: "gender=f",
		icon: null,
	},
	{
		key: "freshers",
		name: "Jobs for freshers",
		slug: "freshers",
		type: "freshers",
		filterQuery: "experience=f",
	},
	{
		key: "10th_pass",
		name: "10th Pass Jobs",
		slug: "10th_pass",
		filterQuery: "education_level=1",
	},
	{
		key: "12th_pass",
		name: "12th Pass Jobs",
		slug: "12th_pass",
		filterQuery: "education_level=2",
	},
	{
		key: "diploma",
		name: "Diploma Jobs",
		slug: null,
		filterQuery: "education_level=3",
	},
	{
		key: "iti",
		name: "ITI Jobs",
		slug: null,
		filterQuery: "education_level=4",
	},
	{
		key: "graduate",
		name: "Graduate Jobs",
		slug: null,
		filterQuery: "education_level=5",
	},
	{
		key: "postGraduate",
		name: "Post Graduate Jobs",
		slug: null,
		filterQuery: "education_level=6",
	},
];

export const JOB_FILTERS_AUTH = [
	{ name: "All", key: "all", slug: "", filterQuery: "" },
	{
		key: "wfh",
		name: "Work from home",
		slug: "work_from_home",
		filterQuery: "is_wfh=true",
	},
	{
		key: "partTime",
		name: "Part time",
		slug: "part_time",
		filterQuery: "job_time=part_time",
	},
	{
		key: "nightShift",
		name: "Night Shift",
		slug: "night_shift",
		filterQuery: "job_shift=night",
	},
];

export const FILTER_TABS = [
	{
		name: "Jobs Near Me",
		key: "jobsNearMe",
		filterKey: "nearby-{categoryId}",
	},
	{
		name: "High Salary Jobs",
		key: "highSalaryJobs",
		filterKey: "mycat-{categoryId}-salary",
	},
	{
		name: "Recommended Jobs",
		key: "recommendedJobs",
		filterKey: "mycat-{categoryId}-enabler",
	},
];

export const CITIES_STATUS = {
	LIVE: 0,
	NON_LIVE: 1,
} as const;
