import React from "react";

function SuitcaseFilledIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="#722ED1"
				fillRule="evenodd"
				d="M11 1a4 4 0 00-4 4H4C2.804 5 1.875 6.174 2.014 7.51l.465 4.5c.118 1.135.972 1.99 1.987 1.99h6.603v-1.202c0-.579.416-1.048.931-1.048.514 0 .931.47.931 1.048V14h6.603c1.015 0 1.87-.855 1.987-1.99l.465-4.5C22.125 6.174 21.196 5 20 5h-3a4 4 0 00-4-4h-2zm0 2a2 2 0 00-2 2h6a2 2 0 00-2-2h-2z"
				clipRule="evenodd"
			></path>
			<path
				fill="#722ED1"
				d="M4 15h7.087v1.086a.914.914 0 101.828 0V15H20a1 1 0 01.992 1.13l-.312 2.388A4 4 0 0116.715 22H7.287a4 4 0 01-3.967-3.482l-.311-2.389A1 1 0 014 15z"
			></path>
		</svg>
	);
}

export default SuitcaseFilledIcon;
