const DegreeNavbarIcon = ({ height = "24", width = "24" }) => (
	<svg
		width={height}
		height={width}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		// eslint-disable-next-line react/jsx-props-no-spreading
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.0056 11.921L1.83484 9.9689C0.668958 9.25111 0.737571 7.53412 1.95701 6.91164L11.2032 2.19185C11.7041 1.93614 12.2973 1.93604 12.7983 2.1916L22.0425 6.9067C22.9882 7.38906 23.2416 8.52873 22.766 9.35109C22.7751 9.39776 22.7799 9.44597 22.7799 9.49529L22.7804 13.5063C22.7804 13.9216 22.4438 14.2584 22.0284 14.2584C21.6131 14.2585 21.2763 13.9218 21.2763 13.5065L21.2759 10.5053L18.9446 11.9236V17.417C18.9446 18.0598 18.5931 18.6512 18.0285 18.9584L12.8372 21.7833C12.3165 22.0666 11.688 22.0679 11.1661 21.7868L5.92811 18.9648C5.3599 18.6587 5.0056 18.0654 5.0056 17.4199V11.921ZM12.1149 3.53149C12.0433 3.49498 11.9586 3.49499 11.887 3.53152L2.64086 8.25132C2.46665 8.34024 2.45685 8.58553 2.62341 8.68807L6.1454 10.8564L11.8717 14.2224C11.9504 14.2686 12.048 14.2685 12.1265 14.2219L17.8053 10.8561L21.3755 8.68406C21.5431 8.58207 21.5339 8.33574 21.3591 8.24658L12.1149 3.53149ZM17.4405 12.8208L12.8934 15.5158C12.3437 15.8417 11.6603 15.8429 11.1095 15.5191L6.50972 12.8153V17.4199C6.50972 17.5121 6.56033 17.5969 6.6415 17.6406L11.8795 20.4626C11.9541 20.5028 12.0439 20.5026 12.1182 20.4621L17.3096 17.6372C17.3903 17.5933 17.4405 17.5088 17.4405 17.417V12.8208Z"
			fill="#8C8594"
		/>
	</svg>
);
export default DegreeNavbarIcon;
