import React from "react";

function CertificateIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="#1F8268"
				d="M13.058 10.204l.767-.004c.487-.51 1.074-1.115 1.842-1.07.77-.042 1.355.554 1.842 1.07.325.013.954-.035 1.259.078v-4.88H5.232v7.792h5.607l.382-.385.008-.767a1.85 1.85 0 011.83-1.834zm-5.16-1.71c-.806-.012-.81-1.217 0-1.23h8.204c.805.014.81 1.215 0 1.23H7.898z"
			></path>
			<path
				fill="#1F8268"
				d="M21.229 2.321H2.77a.613.613 0 00-.615.611v12.72a.617.617 0 00.615.615h8.232c-.545-.482-.948-1.114-.832-1.846H4.617a.617.617 0 01-.615-.615V4.782a.615.615 0 01.615-.615h14.766a.617.617 0 01.615.615v6.649c.156.347.104.995.115 1.374l.541.545c.866.913.603 2.162-.324 2.917h.899a.62.62 0 00.615-.616V2.932a.616.616 0 00-.616-.61zM19.206 19.08l-1.698.013-.783.77.61 1.68a.205.205 0 00.379.017l.57-1.22 1.216.568a.205.205 0 00.28-.255l-.574-1.573zM12.131 19.08l-.572 1.573a.204.204 0 00.161.272c.04.006.081 0 .118-.017l1.216-.569.57 1.222a.205.205 0 00.378-.017l.61-1.676-.787-.775-1.694-.013z"
			></path>
			<path
				fill="#1F8268"
				d="M18.887 13.312l-.009-1.267a.614.614 0 00-.61-.61l-1.267-.009-.902-.889a.614.614 0 00-.862 0l-.902.89-1.267.009a.614.614 0 00-.61.609l-.009 1.267-.89.903a.614.614 0 000 .861l.89.903.01 1.267a.614.614 0 00.609.61l1.267.009.902.89a.614.614 0 00.862 0l.902-.89 1.268-.01a.614.614 0 00.61-.609l.008-1.267.89-.902a.614.614 0 000-.862l-.89-.903zm-3.219 2.974c-2.168-.067-2.168-3.215 0-3.281 2.169.067 2.168 3.214 0 3.281z"
			></path>
		</svg>
	);
}

export default CertificateIcon;
