import React from "react";

function GraduationHatIcon({ fill = "#DE3700" }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill={fill}
				d="M4.06 13.213v2.9c0 .753.42 1.454 1.094 1.815l5.261 2.817c.631.34 1.389.34 2.02 0l5.262-2.817a2.069 2.069 0 001.094-1.816v-2.898l-6.356 3.404c-.631.34-1.389.34-2.02 0l-6.356-3.404zm6.355-9.965l-8.87 4.745a1.03 1.03 0 000 1.816l8.87 4.746c.631.34 1.389.34 2.02 0l8.46-4.53v6.098c0 .567.474 1.031 1.053 1.031.578 0 1.052-.464 1.052-1.031V9.51c0-.382-.21-.722-.547-.908L12.435 3.248a2.185 2.185 0 00-2.02 0z"
			></path>
		</svg>
	);
}

export default GraduationHatIcon;
