const DegreeNavBarFilled = () => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.1749 11.5057L12.5079 14.8645C12.1946 15.0502 11.8052 15.0509 11.4913 14.8664L5.77406 11.5057L1.53323 9.27943C0.824266 8.90724 0.818457 7.8943 1.52311 7.514L11.525 2.11608C11.8213 1.95616 12.1782 1.9561 12.4745 2.11591L22.4772 7.50967C23.1123 7.85216 23.1703 8.70795 22.6582 9.14639C22.7165 9.25307 22.7497 9.37548 22.7497 9.50564L22.7502 13.5056C22.7502 13.9199 22.4145 14.2557 22.0003 14.2557C21.5861 14.2558 21.2502 13.92 21.2502 13.5058L21.2497 9.90798L18.1749 11.5057Z"
			fill="#1F8268"
		/>
		<path
			d="M6.22314 18.3965L11.4469 21.83C11.7822 22.0513 12.2175 22.0526 12.5519 21.83L17.729 18.3968C18.0075 18.2114 18.1749 17.899 18.1749 17.5643V13.0999L12.5104 16.3105C12.1935 16.4901 11.8057 16.4908 11.4882 16.3123L5.77406 13.0999V17.5619C5.77406 17.8979 5.94276 18.2114 6.22314 18.3965Z"
			fill="#1F8268"
		/>
	</svg>
);
export default DegreeNavBarFilled;
