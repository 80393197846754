import React from "react";

function CommunityNavbarIcon({ stroke = "#8C8594" }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			fill="none"
			viewBox="0 0 25 24"
		>
			<path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
			<circle
				cx="8.5"
				cy="10"
				r="2.25"
				fill="#fff"
				stroke={stroke}
				strokeWidth="1.5"
			/>
			<path
				fill="#fff"
				stroke={stroke}
				strokeWidth="1.5"
				d="M3.25 19.67c0-2.74 1.921-4.92 4.958-4.92 3.136 0 5.542 2.274 5.542 4.92a1.58 1.58 0 01-1.58 1.58H4.83a1.58 1.58 0 01-1.58-1.58z"
			/>
			<path
				fill="#fff"
				stroke={stroke}
				strokeWidth="1.5"
				d="M9.25 19.087c0-3.482 2.902-6.337 6.523-6.337 3.545 0 5.977 2.777 5.977 6.337a2.163 2.163 0 01-2.163 2.163h-8.174a2.163 2.163 0 01-2.163-2.163z"
			/>
			<circle
				cx="15.45"
				cy="7"
				r="3.25"
				fill="#fff"
				stroke={stroke}
				strokeWidth="1.5"
			/>
		</svg>
	);
}

export default CommunityNavbarIcon;
