import * as React from "react";

const StarExperienceIcon = (props) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m10 14.043-5.29 2.688 1.01-5.692-4.28-4.03 5.915-.83L10 1l2.645 5.178 5.915.83-4.28 4.031 1.01 5.692L10 14.043Z"
			fill="#fff"
			stroke="#4D3951"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.3 15.813a.9.9 0 1 1 1.8 0V20.1a.9.9 0 1 1-1.8 0v-4.287ZM10.9 15.813a.9.9 0 0 1 1.8 0v2.548a.9.9 0 0 1-1.8 0v-2.548Z"
			fill="#4D3951"
		/>
	</svg>
);

export default StarExperienceIcon;
