import * as React from "react";

const RoundedCloseIcon = ({ width = 32, height = 32, fill = "#190A28", ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect width="32" height="32" rx="16" fill={fill} />
		<path
			d="M21.0968 10.9114C20.7813 10.5959 20.2716 10.5959 19.9561 10.9114L16.0001 14.8593L12.0441 10.9033C11.7286 10.5878 11.2189 10.5878 10.9034 10.9033C10.5879 11.2188 10.5879 11.7285 10.9034 12.044L14.8594 16L10.9034 19.956C10.5879 20.2715 10.5879 20.7812 10.9034 21.0967C11.2189 21.4122 11.7286 21.4122 12.0441 21.0967L16.0001 17.1407L19.9561 21.0967C20.2716 21.4122 20.7813 21.4122 21.0968 21.0967C21.4123 20.7812 21.4123 20.2715 21.0968 19.956L17.1408 16L21.0968 12.044C21.4042 11.7366 21.4042 11.2188 21.0968 10.9114Z"
			fill="white"
		/>
	</svg>
);

export default RoundedCloseIcon;
