// import { rawClient } from "../../../shared/http-client";
import urls from "config/urls";
import { rawClient } from "utils/apiClientPrivate";
import { add91, getLastSavedNumberDetails, remove91 } from "./loginUtils";

export const requestOtp = async (mobileNumber: string) => {
	const retries = getLastSavedNumberDetails()?.otpSendTries || 0;
	const body = {
		hash_type: "original",
		phone_number: add91(mobileNumber),
		request_id: `${Date.now()}`,
		retries: retries || 0,
	};
	try {
		return await rawClient.post(urls.LOGIN_REQUEST_OTP, body);
	} catch (e: any) {
		return e.response;
	}
};

export const verifyOtpWithBE = async (mobileNumber: string, otp: string) => {
	// const isNewLoginFlow = useNewLoginStore.getState().isNewLoginFlow;

	const body = {
		otp,
		phone_number_prefixed: add91(mobileNumber),
		phone_number: remove91(mobileNumber),
		platform: "web",
		request_id: `${Date.now()}`,
	};
	try {
		return await rawClient.post(urls.VERIFY_OTP, body, {
			params: {
				src: "web_apnaco",
			},
		});
	} catch (e: any) {
		return e.response;
	}
};
