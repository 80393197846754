/* eslint-disable import/prefer-default-export */

const customMediaQuery = (minWidth: number, minQuery = true) =>
	`@media (${minQuery ? "min-width" : "max-width"}: ${minWidth}px)`;

const customHeightMediaQuery = (minHeight: number, minQuery = true) =>
	`@media (${minQuery ? "min-height" : "max-height"}: ${minHeight}px)`;

export const bp = {
	xs: customMediaQuery(425),
	sm: customMediaQuery(640),
	md: customMediaQuery(768),
	lg: customMediaQuery(1024),
	xl: customMediaQuery(1280),
	"2xl": customMediaQuery(1536),
};

export const bpMax = {
	xs: customMediaQuery(425, false),
	sm: customMediaQuery(640, false),
	md: customMediaQuery(768, false),
	lg: customMediaQuery(1024, false),
	xl: customMediaQuery(1280, false),
	"2xl": customMediaQuery(1536, false),
};

export const heightMax = {
	xs: customHeightMediaQuery(425, false),
	sm: customHeightMediaQuery(640, false),
	md: customHeightMediaQuery(768, false),
	lg: customHeightMediaQuery(1024, false),
	xl: customHeightMediaQuery(1280, false),
	"2xl": customHeightMediaQuery(1536, false),
};
