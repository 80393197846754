import * as React from "react";

const SuitcaseIconV2 = (props) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.874 3A4.002 4.002 0 0 0 11 0H9a4.002 4.002 0 0 0-4 4H3.285A3 3 0 0 0 .297 7.272l.446 4.91c.034.37.168.71.374.993l.422 4.223A4 4 0 0 0 5.52 21h8.96a4 4 0 0 0 3.98-3.602l.423-4.221c.206-.284.34-.624.374-.996l.446-4.91A3 3 0 0 0 16.715 4H15c0-.345-.044-.68-.126-1ZM11 2H9a2 2 0 0 0-1.732 1h5.465A2 2 0 0 0 11 2ZM3.285 6h13.43a1 1 0 0 1 .995 1.09L17.264 12H11a1 1 0 0 0-2 0H2.735L2.29 7.09A1 1 0 0 1 3.285 6Zm5.714 8H3.21l.32 3.199A2 2 0 0 0 5.52 19h8.96a2 2 0 0 0 1.99-1.801L16.79 14H11a1 1 0 0 1-2 0Z"
			fill="#4D3951"
		/>
	</svg>
);

export default SuitcaseIconV2;
