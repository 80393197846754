const ErrorIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				d="M7.99967 5.16659C8.36634 5.16659 8.66634 5.46659 8.66634 5.83325V8.49992C8.66634 8.86659 8.36634 9.16659 7.99967 9.16659C7.63301 9.16659 7.33301 8.86659 7.33301 8.49992V5.83325C7.33301 5.46659 7.63301 5.16659 7.99967 5.16659ZM7.99301 1.83325C4.31301 1.83325 1.33301 4.81992 1.33301 8.49992C1.33301 12.1799 4.31301 15.1666 7.99301 15.1666C11.6797 15.1666 14.6663 12.1799 14.6663 8.49992C14.6663 4.81992 11.6797 1.83325 7.99301 1.83325ZM7.99967 13.8333C5.05301 13.8333 2.66634 11.4466 2.66634 8.49992C2.66634 5.55325 5.05301 3.16659 7.99967 3.16659C10.9463 3.16659 13.333 5.55325 13.333 8.49992C13.333 11.4466 10.9463 13.8333 7.99967 13.8333ZM8.66634 11.8333H7.33301V10.4999H8.66634V11.8333Z"
				fill="#CC0000"
			/>
		</svg>
	);
};

export default ErrorIcon;
