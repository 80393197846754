/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const FolderIcon = (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_648_254956)">
			<path
				d="M20 6H12L10.59 4.59C10.21 4.21 9.7 4 9.17 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM19 18H5C4.45 18 4 17.55 4 17V9C4 8.45 4.45 8 5 8H19C19.55 8 20 8.45 20 9V17C20 17.55 19.55 18 19 18Z"
				fill="#4D3951"
			/>
		</g>
		<defs>
			<clipPath id="clip0_648_254956">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default FolderIcon;
