import { useEffect, useRef, useState } from "react";
import { trackApnaLearnEvent } from "./trackers";
import { APP_INTERACTION_TYPES } from "utils/app-bridge";
import { handleBackPressListener } from "./helpers";
import { useRouter } from "next/router";
export const useSessionStorage = (key) => {
	const [value, setValue] = useState(null);

	useEffect(() => {
		// Check session storage for the key
		const item = sessionStorage.getItem(key);
		if (item) {
			setValue(item);
		}
	}, [key]);

	return value;
};

export const useTrackScrollIntoView = (mixpanelEventName: string, eventData: object) => {
	const elementRef = useRef<HTMLDivElement>(null);
	const hasTracked = useRef<boolean>(false); // To track if the event has already been triggered

	useEffect(() => {
		const handleIntersection = (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (
					entry.isIntersecting &&
					!hasTracked.current &&
					entry.intersectionRatio >= 0.5
				) {
					// Trigger the Mixpanel event when 50% of the component is in view and it's the first time
					trackApnaLearnEvent(mixpanelEventName, eventData);
					hasTracked.current = true; // Mark the event as triggered
				}
			});
		};

		const observer = new IntersectionObserver(handleIntersection, {
			root: null, // Use the browser viewport
			rootMargin: "0px",
			threshold: 0.5, // Trigger when at least 50% of the component is in view
		});

		if (elementRef.current) {
			observer.observe(elementRef.current);
		}

		// Cleanup the observer on unmount
		return () => {
			if (elementRef.current) {
				observer.unobserve(elementRef.current);
			}
		};
	}, [mixpanelEventName, eventData]);

	return elementRef;
};

// Create a custom hook to handle back press events
export function useBackPressListener(handlers = []) {
	// Update the refs whenever the state changes
	const handlerRefs = useRef(handlers);

	// Update the ref whenever the handlers change
	useEffect(() => {
		handlerRefs.current = handlers;
	}, [handlers]);

	useEffect(() => {
		const listener = () => {
			handleBackPressListener(handlerRefs.current);
		};

		window.addEventListener(APP_INTERACTION_TYPES.BACK_PRESS, listener);

		(window as any).getAppData = (appData: any) => {
			const appDataJson = JSON.parse(appData);
			const event = new CustomEvent(`${appDataJson.type}`, {
				detail: appDataJson?.data,
			});
			window.dispatchEvent(event);
		};

		return () => {
			window.removeEventListener(APP_INTERACTION_TYPES.BACK_PRESS, listener);
		};
	}, []);
}

export function useSyncWithQueryParams(
	keyForm,
	keyOtp,
	formState,
	setFormState,
	otpState,
	setOtpState
) {
	const router = useRouter();

	useEffect(() => {
		const handleSearchChange = () => {
			const queryParams = new URLSearchParams(window.location.search);

			if (queryParams.has(keyForm)) {
				setFormState(true);
			} else {
				setFormState(false);
			}

			if (queryParams.has(keyOtp)) {
				setOtpState(true);
			} else {
				setOtpState(false);
			}
		};

		window.addEventListener("popstate", handleSearchChange);

		return () => {
			window.removeEventListener("popstate", handleSearchChange);
		};
	}, [setFormState, setOtpState]);

	// Update the query parameters whenever the form state changes
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		if (formState) {
			if (!queryParams.has(keyForm)) {
				queryParams.set(keyForm, "true");
				router.push(
					`${window.location.pathname}?${queryParams.toString()}`,
					null,
					{
						shallow: true,
						scroll: false,
					}
				);
			}
		} else {
			if (queryParams.has(keyForm)) {
				window.history.back();
			}
		}
	}, [formState]);

	// Update the query parameters whenever the OTP state changes
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);

		if (otpState) {
			if (!queryParams.has(keyOtp)) {
				queryParams.set(keyOtp, "true");
				// Remove form query param if OTP is shown
				if (queryParams.has(keyForm)) {
					queryParams.delete(keyForm);
					router
						.replace(
							`${window.location.pathname}?${queryParams.toString()}`,
							null,
							{
								shallow: true,
								scroll: false,
							}
						)
						.then(() => {
							setFormState(false);
						});
				} else {
					router.push(
						`${window.location.pathname}?${queryParams.toString()}`,
						null,
						{
							shallow: true,
							scroll: false,
						}
					);
				}
			}
		} else {
			if (queryParams.has(keyOtp)) {
				window.history.back();
			}
		}
	}, [otpState, setFormState]);
}
