/* eslint-disable react/jsx-props-no-spreading */
const StarterBadge = (props) => (
	<svg width={104} height={32} viewBox="0 0 104 32" {...props}>
		<g fill="none" fillRule="evenodd">
			<path
				fill="#FFF"
				stroke="#A5A5A5"
				strokeOpacity={0.5}
				d="M92.308 4.5H24.692c-2.533 0-3.459.246-4.387.742a4.962 4.962 0 00-2.063 2.063c-.496.928-.742 1.855-.742 4.387v8.616c0 2.533.246 3.459.742 4.387a4.962 4.962 0 002.063 2.063c.928.496 1.854.742 4.387.742h67.616c2.533 0 3.459-.246 4.387-.742a4.962 4.962 0 002.063-2.063c.496-.928.742-1.854.742-4.387v-8.616c0-2.533-.246-3.459-.742-4.387a4.962 4.962 0 00-2.063-2.063c-.928-.496-1.855-.742-4.387-.742z"
			/>
			<path
				fill="#6A6A6A"
				fillRule="nonzero"
				d="M44.264 20.204c.68 0 1.268-.11 1.764-.33.496-.22.876-.528 1.14-.924a2.42 2.42 0 00.396-1.374c0-.728-.2-1.29-.6-1.686-.4-.396-1.024-.73-1.872-1.002a9.387 9.387 0 01-1.008-.372c-.224-.104-.38-.214-.468-.33a.724.724 0 01-.132-.45c0-.264.1-.466.3-.606.2-.14.472-.21.816-.21.648 0 1.256.232 1.824.696l.912-1.068a3.73 3.73 0 00-1.254-.804c-.46-.176-1.002-.264-1.626-.264-.576 0-1.092.098-1.548.294-.456.196-.812.476-1.068.84s-.384.786-.384 1.266c0 .624.194 1.14.582 1.548.388.408 1.042.752 1.962 1.032a6.1 6.1 0 01.936.36c.216.112.368.238.456.378.088.14.132.314.132.522 0 .32-.118.57-.354.75-.236.18-.558.27-.966.27-.4 0-.77-.068-1.11-.204-.34-.136-.67-.34-.99-.612l-.996 1.092c.376.36.826.648 1.35.864.524.216 1.126.324 1.806.324zM52.336 20v-6.804h2.052l.192-1.512h-6.36v1.512h2.148V20h1.968zm4.04 0l.42-1.788h2.424L59.652 20h2.088l-2.508-8.316h-2.388L54.336 20h2.04zm2.508-3.216h-1.752l.876-3.672.876 3.672zM64.82 20v-3.18h.768L67.292 20h2.22L67.4 16.46c.552-.248.956-.55 1.212-.906s.384-.802.384-1.338c0-.856-.286-1.492-.858-1.908-.572-.416-1.426-.624-2.562-.624h-2.724V20h1.968zm.828-4.536h-.828v-2.4h.708c.488 0 .85.092 1.086.276.236.184.354.476.354.876 0 .432-.11.748-.33.948-.22.2-.55.3-.99.3zM73.96 20v-6.804h2.052l.192-1.512h-6.36v1.512h2.148V20h1.968zm8.636 0v-1.416h-3.132V16.46h2.556v-1.38h-2.556v-1.992h2.928l.204-1.404h-5.1V20h5.1zm3.68 0v-3.18h.768L88.748 20h2.22l-2.112-3.54c.552-.248.956-.55 1.212-.906s.384-.802.384-1.338c0-.856-.286-1.492-.858-1.908-.572-.416-1.426-.624-2.562-.624h-2.724V20h1.968zm.828-4.536h-.828v-2.4h.708c.488 0 .85.092 1.086.276.236.184.354.476.354.876 0 .432-.11.748-.33.948-.22.2-.55.3-.99.3z"
			/>
			<g
				fill="#FFF"
				stroke="#A5A5A5"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
				<path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
				<path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
			</g>
			<g
				fill="#FFF"
				stroke="#6A6A6A"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
				<path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
				<path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
			</g>
		</g>
	</svg>
);

export default StarterBadge;
