/* eslint-disable react/jsx-props-no-spreading */
const InfluencerBadge = (props) => (
	<svg width={144} height={32} viewBox="0 0 144 32" {...props}>
		<defs>
			<linearGradient id="prefix__a" x1="50%" x2="50%" y1="100%" y2="0%">
				<stop offset="0%" stopColor="#FE5196" />
				<stop offset="100%" stopColor="#F77062" />
			</linearGradient>
			<linearGradient id="prefix__b" x1="50%" x2="50%" y1="100%" y2="0%">
				<stop offset="0%" stopColor="#FE5196" />
				<stop offset="100%" stopColor="#F77062" />
			</linearGradient>
		</defs>
		<g fill="none" fillRule="evenodd">
			<path
				fill="url(#prefix__a)"
				d="M7.692 0h87.616c2.675 0 3.645.278 4.623.801a5.456 5.456 0 012.268 2.268c.523.978.801 1.948.801 4.623v8.616c0 2.675-.278 3.645-.801 4.623a5.456 5.456 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H7.692c-2.675 0-3.645-.278-4.623-.801a5.456 5.456 0 01-2.268-2.268C.278 19.953 0 18.983 0 16.308V7.692c0-2.675.278-3.645.801-4.623A5.456 5.456 0 013.069.801C4.047.278 5.017 0 7.692 0z"
				transform="translate(17 4)"
			/>
			<path
				fill="#FFF"
				fillRule="nonzero"
				d="M43.748 20v-8.316H41.78V20h1.968zm3.8 0v-3.048c0-.664-.022-1.238-.066-1.722a48.437 48.437 0 00-.186-1.674l2.4 6.444h2.508v-8.316H50.44v3.36c0 .968.088 2 .264 3.096l-2.46-6.456h-2.448V20h1.752zm8.672 0v-3.3h2.424v-1.404H56.22v-2.208h2.784l.204-1.404h-4.956V20h1.968zm9.38 0l.204-1.56h-3.24v-6.756h-1.968V20H65.6zm4.472.204c.704 0 1.3-.128 1.788-.384s.854-.608 1.098-1.056c.244-.448.366-.956.366-1.524v-5.556h-1.968v5.472c0 .536-.102.934-.306 1.194-.204.26-.53.39-.978.39-.44 0-.762-.13-.966-.39-.204-.26-.306-.658-.306-1.194v-5.472h-1.968v5.556c0 .904.276 1.624.828 2.16.552.536 1.356.804 2.412.804zM80.364 20v-1.416h-3.132V16.46h2.556v-1.38h-2.556v-1.992h2.928l.204-1.404h-5.1V20h5.1zm3.464 0v-3.048c0-.664-.022-1.238-.066-1.722a48.437 48.437 0 00-.186-1.674l2.4 6.444h2.508v-8.316H86.72v3.36c0 .968.088 2 .264 3.096l-2.46-6.456h-2.448V20h1.752zm10.124.204c.536 0 1.026-.1 1.47-.3.444-.2.822-.456 1.134-.768l-.876-1.128a4.652 4.652 0 01-.804.498 1.93 1.93 0 01-.828.174c-.576 0-1.026-.234-1.35-.702-.324-.468-.486-1.186-.486-2.154 0-.984.162-1.712.486-2.184.324-.472.766-.708 1.326-.708.272 0 .528.048.768.144.24.096.484.24.732.432l.936-1.116a3.906 3.906 0 00-1.146-.684 3.919 3.919 0 00-1.386-.228c-.728 0-1.378.17-1.95.51-.572.34-1.02.838-1.344 1.494-.324.656-.486 1.436-.486 2.34 0 .928.158 1.722.474 2.382.316.66.758 1.158 1.326 1.494.568.336 1.236.504 2.004.504zm9.08-.204v-1.416H99.9V16.46h2.556v-1.38H99.9v-1.992h2.928l.204-1.404h-5.1V20h5.1zm3.68 0v-3.18h.768l1.704 3.18h2.22l-2.112-3.54c.552-.248.956-.55 1.212-.906s.384-.802.384-1.338c0-.856-.286-1.492-.858-1.908-.572-.416-1.426-.624-2.562-.624h-2.724V20h1.968zm.828-4.536h-.828v-2.4h.708c.488 0 .85.092 1.086.276.236.184.354.476.354.876 0 .432-.11.748-.33.948-.22.2-.55.3-.99.3z"
			/>
			<g
				fill="#FFF"
				stroke="#FB9A95"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
				<path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
				<path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
			</g>
			<g
				fill="#FFF"
				stroke="#FD578E"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
				<path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
				<path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
			</g>
			<path
				fill="url(#prefix__b)"
				d="M129.131 14.131L132 13l-2.869-1.131L128 9l-1.131 2.869L124 13l2.869 1.131L128 17zm0 12L132 25l-2.869-1.131L128 21l-1.131 2.869L124 25l2.869 1.131L128 29zm9.566-5.434L143 19l-4.303-1.697L137 13l-1.697 4.303L131 19l4.303 1.697L137 25z"
				transform="translate(-2 -3)"
			/>
		</g>
	</svg>
);

export default InfluencerBadge;
