/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonProps } from "@material-ui/core";
import React, { ReactNode } from "react";

type CustomButtonProps = ButtonProps & {
	onClick: () => void;
	children: ReactNode;
};
const CTAButton: React.FC<CustomButtonProps> = (props) => {
	const { onClick, children, disabled, ...buttonProps } = props;
	return (
		<Button
			variant="outlined"
			{...buttonProps}
			fullWidth
			onClick={onClick}
			disabled={disabled}
			style={{
				backgroundColor: disabled ? "#CBCBCB" : "#1F8268",
				fontFamily: "Inter",
				fontSize: "16px",
				fontWeight: 600,
				color: !disabled && "white",
			}}
		>
			{children}
		</Button>
	);
};

export default CTAButton;
