/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import OTPScreen from "./OTPScreen";
import NumberScreen from "./NumberScreen";
import CrossIcon from "../../assets/svgs/CrossIcon";
import { USER_AUTH } from "./constants";
import RoundedCloseIcon from "../../assets/svgs/RoundedCloseIcon";
import useIsDesktop from "../../hooks/useIsDesktop";

const LoginRefactored = ({ onCloseModal, handleManualDropOffAuthFlow }) => {
	const router = useRouter();
	const isDesktopView = useIsDesktop();

	const [screen, setScreen] = useState(USER_AUTH.NUMBER_SCREEN);

	useEffect(() => setScreen(router.query?.user_auth as string), [router.query]);

	return (
		<div className={clsx("relative ", { "pb-[26px]": !isDesktopView })}>
			{isDesktopView && (
				<div
					className="absolute right-[8px] top-[8px] cursor-pointer"
					onClick={handleManualDropOffAuthFlow}
				>
					<CrossIcon />
				</div>
			)}
			{!isDesktopView && (
				<div
					onClick={handleManualDropOffAuthFlow}
					className="absolute right-[16px] top-[-48px]"
				>
					<RoundedCloseIcon />
				</div>
			)}
			{screen === USER_AUTH.OTP_SCREEN && <OTPScreen onCloseModal={onCloseModal} />}
			{screen === USER_AUTH.NUMBER_SCREEN && <NumberScreen />}
		</div>
	);
};

export default LoginRefactored;
