/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const InfoIconLightProfileEditor = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
		<path
			fill="#8C8594"
			d="M7 .333A6.67 6.67 0 0 0 .333 7 6.67 6.67 0 0 0 7 13.667 6.67 6.67 0 0 0 13.666 7 6.669 6.669 0 0 0 7 .333Zm0 10a.669.669 0 0 1-.667-.666V7c0-.367.3-.667.667-.667.366 0 .666.3.666.667v2.667c0 .366-.3.666-.666.666ZM7.666 5H6.333V3.667h1.333V5Z"
		/>
	</svg>
);
export default InfoIconLightProfileEditor;
