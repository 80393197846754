import clsx from "clsx";
import useIsDesktop from "hooks/useIsDesktop";
import { ReactNode } from "react";

type Props = {
	type: "error" | "info";
	message: ReactNode;
};

const ErrorLabel = ({ type, message }: Props) => {
	const isDesktop = useIsDesktop();
	return (
		<div
			className={clsx("flex items-start justify-start gap-1 text-xs", {
				"text-[#CC0000]": type === "error",
				"text-[#8C8594]": type === "info",
			})}
		>
			<svg
				style={{ marginTop: "2px" }}
				width="14"
				height="14"
				viewBox="0 0 14 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M7.00065 0.333252C3.32065 0.333252 0.333984 3.31992 0.333984 6.99992C0.333984 10.6799 3.32065 13.6666 7.00065 13.6666C10.6807 13.6666 13.6673 10.6799 13.6673 6.99992C13.6673 3.31992 10.6807 0.333252 7.00065 0.333252ZM7.00065 7.66659C6.63398 7.66659 6.33398 7.36659 6.33398 6.99992V4.33325C6.33398 3.96659 6.63398 3.66659 7.00065 3.66659C7.36732 3.66659 7.66732 3.96659 7.66732 4.33325V6.99992C7.66732 7.36659 7.36732 7.66659 7.00065 7.66659ZM7.66732 10.3333H6.33398V8.99992H7.66732V10.3333Z"
					fill={type === "error" ? "#CC0000" : "#8C8594"}
				/>
			</svg>

			<span
				style={{
					marginLeft: "4px",
					lineHeight: "20px",
					fontSize: isDesktop ? "14px" : "12px",
				}}
			>
				{message}
			</span>
		</div>
	);
};
export default ErrorLabel;
