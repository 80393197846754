import { FC } from "react";
import OTPInput from "react-otp-input";

type Props = {
	handleInputChange: (event: string) => void;
	otpValue: string;
	isOtpError: boolean;
};

const OtpInput: FC<Props> = ({ handleInputChange, otpValue, isOtpError = false }) => (
	<OTPInput
		onChange={handleInputChange}
		value={otpValue}
		numInputs={4}
		containerStyle="otp-input-container-styles"
		inputStyle={isOtpError ? "otp-input-styles-error" : "otp-input-styles"}
		shouldAutoFocus
		renderInput={(props) => <input type="number" {...props} />}
		isInputNum
	/>
);
export default OtpInput;
