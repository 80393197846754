/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import useIsDesktop from "hooks/useIsDesktop";
import TickIcon24 from "assets/svgs/TickIcon24";
import WarningIcon24 from "assets/svgs/WarningIcon24";
import CloseIcon20 from "assets/svgs/CloseIcon20";
import ErrorIcon24 from "assets/svgs/ErrorIcon24";
import clsx from "clsx";
import {
	ContentSection,
	Heading,
	LeftSection,
	RightSection,
	ToastContainer,
	IconContainer,
} from "./styled";
import { ToastProps } from "./types";

const IconType = {
	success: <TickIcon24 />,
	error: <ErrorIcon24 />,
	warning: <WarningIcon24 />,
};

const ColorCode = {
	success: "#2BB792",
	error: "#CC0000",
	warning: "#FF6937",
};

const Toast: React.FC<ToastProps> = ({
	heading,
	onClose,
	type = "success",
	buttonText = "",
	onBtnClick = () => {},
	hideCloseBtn = false,
}) => {
	const isDesktopView = useIsDesktop();
	return (
		<ToastContainer
			style={{ borderLeft: isDesktopView ? `4px solid ${ColorCode[type]}` : "" }}
		>
			<LeftSection>
				<IconContainer>{IconType[type]}</IconContainer>
				<ContentSection>
					<Heading>{heading}</Heading>
					{buttonText && isDesktopView && (
						<div
							className={clsx(
								"mt-[4px] w-fit cursor-pointer text-[14px] font-[600] leading-[20px]"
							)}
							style={{ color: ColorCode[type] }}
							onClick={onBtnClick}
						>
							{buttonText}
						</div>
					)}
				</ContentSection>
			</LeftSection>
			<RightSection>
				{buttonText && !isDesktopView && (
					<div
						className={clsx(
							"max-w-[84px] shrink-0 cursor-pointer p-[8px] text-center text-[12px] font-[600] leading-[16px]"
						)}
						style={{ color: ColorCode[type] }}
						onClick={onBtnClick}
					>
						{buttonText}
					</div>
				)}
				{!hideCloseBtn && (
					<div className={clsx("cursor-pointer")} onClick={onClose}>
						<CloseIcon20 />
					</div>
				)}
			</RightSection>
		</ToastContainer>
	);
};

export default Toast;
