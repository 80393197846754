/* eslint-disable prettier/prettier */
import { trackOnMixpanel } from "modules/candidate/profile/utils/MixpanelHelper";

export interface AuthSource {
    screen: string;
    source: string;
}

const trackAuthMixpanelEvents = (eventName: string, entities: any = {}) => {
    const userSource: AuthSource = JSON.parse(
        sessionStorage.getItem("auth_analytics_data")
    );
    trackOnMixpanel(eventName, {
        ...entities,
        screen: userSource?.screen || "external",
        source: userSource?.source || "non job context login",
    });
};

export const trackOnBoardingLoginSuccess = (userType: string, number: string, isProfileEnriched: boolean) => {
    trackAuthMixpanelEvents("Web onboarding login success", {
        userType,
        mobile_number_entered: number,
        profile_enriched: isProfileEnriched ? "true" : "false"
    });
};

export const trackWebMobileenterScreenShown = () => {
    trackAuthMixpanelEvents("Web mobile enter screen shown");
};

export const trackWebMobileNumberEntered = (mobileNumber: string) => {
    trackAuthMixpanelEvents("Web Mobile number Entered", {
        mobile_number_entered: mobileNumber
    });
};

export const trackWebOtpSent = (number: string) => {
    trackAuthMixpanelEvents("Web OTP Sent", {
        mobile_number_entered: number
    });
};

export const trackWebOtpEntered = (number: string) => {
    trackAuthMixpanelEvents("Web OTP Entered", {
        mobile_number_entered: number
    });
};

export const trackWebOtpError = (error: string, number: string) => {
    trackAuthMixpanelEvents("Web OTP Entered", {
        error,
        mobile_number_entered: number
    });
};
