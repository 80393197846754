import { trackAnalyticsEvent } from "utils/app-bridge";
import Mixpanel from "utils/Mixpanel";
import Clevertap from "libs/Clevertap";
import { LEARN_USER_DATA } from "./constants";
import { getStringFromSessionStorage, isMobile } from "./helpers";

export const trackApnaLearnEvent = (eventName, props) => {
	const { pageName } = props;
	const userDataString = sessionStorage.getItem(LEARN_USER_DATA);
	let userData = null;
	const utm_source = getStringFromSessionStorage("utm_source");
	const utm_medium = getStringFromSessionStorage("utm_medium");
	const utm_campaign = getStringFromSessionStorage("utm_campaign");
	// Parse userData if it exists
	if (userDataString) {
		try {
			userData = JSON.parse(userDataString);
		} catch (error) {
			console.error("Failed to parse userData from sessionStorage", error);
		}
	}

	if (userData?.id) {
		const eventDataMap = {
			"User id": userData?.id || null,
			PageType: pageName,
			// "Phone": parsedUserData?.phone || formState.mobile || null, // This seems to be commented out in the original code
			web_logged_in: userData?.id ? "Yes" : "No",
			"UTM Medium": utm_medium,
			Campaign: utm_campaign,
			"Device type": isMobile() ? "Mobile" : "Desktop",
			"UTM source": utm_source,
			...props, // Include any additional properties passed to the tracking function
		};

		trackAnalyticsEvent(eventName, eventDataMap, ["MIXPANEL"]);
		return;
	}

	Mixpanel.track(eventName, {
		"User id": userData?.id || null,
		PageType: pageName,
		// Phone: parsedUserData?.phone || formState.mobile || null,
		web_logged_in: userData?.id ? "Yes" : "No",
		"UTM Medium": utm_medium,
		Campaign: utm_campaign,
		"Device type": isMobile() ? "Mobile" : "Desktop",
		"UTM source": utm_source,
		...props,
	});

	if (
		eventName === "Learn Page View" ||
		eventName === "Get Free Counselling button clicked" ||
		eventName.includes("Counselling form submitted")
	) {
		Clevertap.trackEvent(eventName, {
			"User id": userData?.id || null,
			PageType: pageName,
			web_logged_in: userData?.id ? "Yes" : "No",
			"UTM Medium": utm_medium,
			Campaign: utm_campaign,
			"Device type": isMobile() ? "Mobile" : "Desktop",
			"UTM source": utm_source,
			...props,
		});
	}
};

export default trackApnaLearnEvent;
