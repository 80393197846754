import React from "react";

function JobsNavbarIcon({ stroke = "#8C8594" }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			fill="none"
			viewBox="0 0 25 24"
		>
			<path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
			<path
				stroke={stroke}
				strokeWidth="1.5"
				d="M3.25 8.5A2.25 2.25 0 015.5 6.25h14.003a2.25 2.25 0 012.25 2.25v9.669a2.25 2.25 0 01-2.25 2.25H5.5a2.25 2.25 0 01-2.25-2.25V8.5z"
			/>
			<path
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M4.52 6.813l2.23 5.441a2 2 0 001.85 1.242h7.915a2 2 0 001.86-1.265l2.14-5.418"
			/>
			<path
				stroke={stroke}
				strokeWidth="1.5"
				d="M9.4 3.75h6.2c.635 0 1.15.515 1.15 1.15v1.35h-8.5V4.9c0-.635.515-1.15 1.15-1.15z"
			/>
		</svg>
	);
}

export default JobsNavbarIcon;
