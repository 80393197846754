const source = () => {
	let referrer = "";
	if (typeof window !== "undefined" && typeof document !== "undefined") {
		if (sessionStorage.getItem("Internal Navigation")) {
			referrer = "Internal Navigation";
		} else if (document.referrer.search("https?://(.*)google.([^/?]*)") === 0) {
			referrer = "Google";
		} else if (document.referrer.search("https?://(.*)bing.([^/?]*)") === 0) {
			referrer = "Google";
		} else if (document.referrer.search("https?://(.*)yahoo.([^/?]*)") === 0) {
			referrer = "Yahoo";
		} else if (document.referrer.search("https?://(.*)facebook.([^/?]*)") === 0) {
			referrer = "Facebook";
		} else if (document.referrer.search("https?://(.*)twitter.([^/?]*)") === 0) {
			referrer = "Twitter";
		} else if (
			document.referrer === "" &&
			!sessionStorage.getItem("Internal Navigation")
		) {
			referrer = "Direct";
		} else {
			referrer = "Other";
		}

		if (!sessionStorage.getItem("Internal Navigation")) {
			setTimeout(() => {
				sessionStorage.setItem("Internal Navigation", "true");
			}, 2000);
		}
		return referrer;
	}
	return referrer;
};

export default source;
