import styled from "styled-components";
import { bp } from "theme";

export const ToastContainer = styled.div`
	display: flex;
	padding: 8px 12px;
	width: 382px;
	height: auto;
	justify-content: space-between;
	background: #190a28;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12), 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
	border-radius: 4px;
	margin-bottom: 24px;
	box-sizing: border-box;
	color: #ffffff;
	gap: 8px;

	${bp.md} {
		align-items: center;
		padding: 16px;
		margin-bottom: 45px;
		width: max-content;
		max-width: 450px;
	}
`;

export const LeftSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center !important;
	${bp.md} {
		align-items: flex-start;
	}
`;

export const IconContainer = styled.div``;

export const ContentSection = styled.div`
	margin-left: 8px;
	display: flex;
	align-items: center;
	${bp.md} {
		display: block;
	}
`;

export const RightSection = styled.div`
	display: flex;
	flex-shrink: 0;
	justify-content: flex-start;
	align-items: center;
	${bp.md} {
		align-items: flex-start;
	}
`;

export const Heading = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	display: block;
	cursor: default;
	/* ellipsis */
	/* max-height: 32px; */
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;

	${bp.md} {
		max-height: 40px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		-webkit-line-clamp: 2;
	}
`;

export const SubHeading = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	display: none;

	${bp.md} {
		display: block;
	}
`;
