export const typeToCategoryMapping = {
	0: "job_feed",
	1: "job_section",
	2: "job_category_section",
	3: "job_card_square",
	4: "two_vertical_job_card_squares",
	5: "job_collection",
	6: "job_vertical_section",
	7: "job_collection_section",
	8: "job_collection_card",
	9: "profile_carousel",
	10: "profile_carousel_banner",
	11: "empty_result_section",
	12: "job_collection_section_compact",
	13: "job_collection_card_compact",
	14: "custom_node",
};

export const categoryToTypeMapping = {
	job_feed: 0,
	job_section: 1,
	job_category_section: 2,
	job_card_square: 3,
	two_vertical_job_card_squares: 4,
	job_collection: 5,
	job_vertical_section: 6,
	job_collection_section: 7,
	job_collection_card: 8,
	profile_carousel: 9,
	profile_carousel_banner: 10,
	empty_result_section: 11,
	job_collection_section_compact: 12,
	job_collection_card_compact: 13,
	custom_node: 14,
};

export const jobFeedApiParser = (feedResponse) => {
	const jobsList = [];

	function parseChildren(element) {
		const category = typeToCategoryMapping[element.type]; // Use category dynamically
		let jobObject = {
			id: element.id, // Preserve the id
			type: element.type,
			data: {},
		};

		// Handle the case for type 3 "job_card_square"
		if (
			element.type === categoryToTypeMapping.job_card_square &&
			element.data[category] &&
			element.data[category].job
		) {
			jobObject = {
				id: element.id, // Preserve the id
				type: element.type,
				data: element.data[category].job, // Directly assign the job data
			};
			return jobObject;
		}

		// Handle the case for type 5 "job_collection"
		if (
			element.type === categoryToTypeMapping.job_collection &&
			element.data[category]
		) {
			const collectionData = element.data[category];
			jobObject = {
				id: element.id, // Preserve the id
				type: element.type,
				data: {
					name: collectionData.name || "",
					subtitle: collectionData.subtitle || "",
					ui_element: collectionData.ui_element || {},
					children:
						collectionData.children?.elements.map((child) =>
							parseChildren(child)
						) || [],
				},
			};
			return jobObject;
		}

		// Handle the case for type 6 "job_vertical_section"
		if (
			element.type === categoryToTypeMapping.job_vertical_section &&
			element.data[category]
		) {
			const verticalSectionData = element.data[category];
			jobObject = {
				id: element.id, // Preserve the id
				type: element.type,
				data: {
					title: verticalSectionData.title || "",
					subtitle: verticalSectionData.subtitle || "",
					children: {
						elements:
							verticalSectionData.children?.elements.map((child) =>
								parseChildren(child)
							) || [],
						pagination: verticalSectionData.children?.pagination,
					},
				},
			};
			return jobObject;
		}

		// Handle the case for type 12 "job_collection_section_compact"
		if (
			element.type === categoryToTypeMapping.job_collection_section_compact &&
			element.data[category]
		) {
			const compactData = element.data[category];
			jobObject = {
				id: element.id, // Preserve the id
				type: element.type,
				data: {
					title: compactData.title || "",
					subtitle: compactData.subtitle || "",
					ui_element: compactData.ui_element || {},
					children: {
						elements:
							compactData.children?.elements.map((child) =>
								parseChildren(child)
							) || [],
						pagination: compactData.children?.pagination,
					},
				},
			};
			return jobObject;
		}

		// Handle the case for type 13 "job_collection_card_compact"
		if (
			element.type === categoryToTypeMapping.job_collection_card_compact &&
			element.data[category]
		) {
			jobObject = {
				id: element.id, // Preserve the id
				type: element.type,
				data: element.data[category], // Directly assign job_collection_card_compact contents
			};
			return jobObject;
		}

		// For all other types, loop through the properties of "data" and copy them over
		if (element.data[category]) {
			// If "children" exists, recursively parse the elements in "children"
			if (
				element.data[category].children &&
				element.data[category].children.elements
			) {
				jobObject.data[category] = {
					children: element.data[category].children.elements.map((child) =>
						parseChildren(child)
					),
				};
			} else {
				// Otherwise, just copy the data as it is
				jobObject.data[category] = element.data[category];
			}
		}

		return jobObject;
	}

	// Start the recursion with the root element
	jobsList.push(parseChildren(feedResponse));

	const result =
		jobsList?.[0]?.data?.job_feed?.children?.[0]?.data?.job_vertical_section
			?.children;

	return jobsList || result || []; // Return the result, or an empty array if undefined
};

/*
	user-profile-orchestrator will return different types of response for job list in different cases.
	The jobsListTransformer function will take the response value of user-profile-orchestrator and return
	an array that will render irrespective of page being feed, search or nav/footer links.
	UPO: user-profile-orchestrator
*/
export const jobsListTransformer = (response) => {
	/*
		In case of search, UPO will return jobs array. 
		In case of feed, UPO will also return job_feed array.

		The UI will check every element of the return array of this function for a type and render
		a skeleton accordingly, which will be populated with the accompanying data of the element.

		FOR SEARCH, we modify the input array to add a type and data value. 
		Type will always be "3" and data will be the job data.

		FOR FEED, we flatten the response and create the same structure as for search, 
		but since feed will also have modules, the type will be dynamic.
	*/

	let jobsList = [];
	let pagination = null;

	const processFeed = (jobFeed, type) => {
		const parsedObject = jobFeedApiParser(jobFeed);
		const firstParsedElement = parsedObject?.[0];
		if (firstParsedElement?.type === type) {
			const elements = firstParsedElement?.data?.children?.elements;
			if (Array.isArray(elements)) {
				jobsList = elements;
				pagination = firstParsedElement?.data?.children?.pagination;
				if (elements[0]?.type === 11) {
					jobsList = [];
					pagination.total_size = 0;
				}
			}
		}
	};

	// Handle feed response -> Root or nested level
	if (response?.job_feed) {
		const jobFeed = response.job_feed;
		if (jobFeed?.empty_result_section) {
			return { jobsList: [], pagination: null };
		}

		// Root level feed
		if (jobFeed?.element?.id) {
			processFeed(jobFeed.element, categoryToTypeMapping.job_feed);
		}

		// Nested level feed
		if (jobFeed?.data?.job_vertical_section?.title) {
			processFeed(jobFeed, categoryToTypeMapping.job_vertical_section);
		}

		return { jobsList, pagination };
	}

	// Handle search response
	if (Array.isArray(response?.jobs) && response.jobs.length > 0) {
		jobsList = response.jobs.map((job) => ({
			type: categoryToTypeMapping.job_card_square,
			data: job,
		}));

		return { jobsList, pagination };
	}

	// Default case
	return { jobsList: [], pagination: null };
};
