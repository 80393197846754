import React from "react";

function HomeNavbarIcon({ fill = "#8C8594" }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			fill="none"
			viewBox="0 0 25 24"
		>
			<path
				fill={fill}
				stroke="#fff"
				strokeWidth="0.2"
				d="M11.214 2.636h0l-.002.002h0L3.04 10.769v.001a1.458 1.458 0 00-.358 1.53c.164.473.62.954 1.31.954h.762v6.665c0 1.007.763 1.981 1.879 1.981h2.934c1.117 0 1.879-.974 1.879-1.98v-4.505h2.113v4.504c0 1.007.762 1.981 1.878 1.981h2.935c1.116 0 1.878-.974 1.878-1.98V13.253h.763c.695 0 1.147-.486 1.307-.96a1.468 1.468 0 00-.352-1.52v-.001L13.79 2.636s0 0 0 0a1.817 1.817 0 00-2.576 0zm1.218 1.327l.07-.07.07.07 7.357 7.32.171.17h-1.65v8.466a.253.253 0 01-.035.133l-.03.048h-2.962l-.03-.048a.253.253 0 01-.034-.133v-6.303H9.646v6.303a.253.253 0 01-.035.133l-.03.048H6.62l-.03-.048a.253.253 0 01-.035-.133v-8.465h-1.65l.172-.17 7.356-7.32z"
			/>
		</svg>
	);
}

export default HomeNavbarIcon;
