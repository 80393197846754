function LogoutIcon(props) {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 4.16667C9.77899 4.16667 9.56702 4.25446 9.41074 4.41074C9.25446 4.56702 9.16667 4.77899 9.16667 5V6.66667C9.16667 7.1269 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.1269 7.5 6.66667V5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5H15.8333C16.4964 2.5 17.1323 2.76339 17.6011 3.23223C18.0699 3.70107 18.3333 4.33696 18.3333 5V15C18.3333 15.663 18.0699 16.2989 17.6011 16.7678C17.1323 17.2366 16.4964 17.5 15.8333 17.5H10C9.33696 17.5 8.70107 17.2366 8.23223 16.7678C7.76339 16.2989 7.5 15.663 7.5 15V13.3333C7.5 12.8731 7.8731 12.5 8.33333 12.5C8.79357 12.5 9.16667 12.8731 9.16667 13.3333V15C9.16667 15.221 9.25446 15.433 9.41074 15.5893C9.56703 15.7455 9.77899 15.8333 10 15.8333H15.8333C16.0543 15.8333 16.2663 15.7455 16.4226 15.5893C16.5789 15.433 16.6667 15.221 16.6667 15V5C16.6667 4.77899 16.5789 4.56702 16.4226 4.41074C16.2663 4.25446 16.0543 4.16667 15.8333 4.16667H10Z"
				fill="#190A28"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.58936 6.91075C5.9148 7.23619 5.9148 7.76382 5.58936 8.08926L4.51195 9.16667H12.5001C12.9603 9.16667 13.3334 9.53977 13.3334 10C13.3334 10.4602 12.9603 10.8333 12.5001 10.8333H2.50011C2.16305 10.8333 1.85919 10.6303 1.73021 10.3189C1.60122 10.0075 1.67252 9.64908 1.91085 9.41075L4.41085 6.91075C4.73629 6.58531 5.26392 6.58531 5.58936 6.91075Z"
				fill="#190A28"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.91083 9.41075C2.23626 9.08531 2.7639 9.08531 3.08934 9.41075L5.58934 11.9107C5.91477 12.2362 5.91477 12.7638 5.58934 13.0893C5.2639 13.4147 4.73626 13.4147 4.41083 13.0893L1.91083 10.5893C1.58539 10.2638 1.58539 9.73619 1.91083 9.41075Z"
				fill="#190A28"
			/>
		</svg>
	);
}
export default LogoutIcon;
