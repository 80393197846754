export const SALARY_SCALE = {
	0: 0,
	1: 10000,
	2: 20000,
	3: 30000,
	4: 40000,
	5: 50000,
	6: 60000,
	7: 70000,
	8: 80000,
	9: 90000,
	10: 100000,
	11: 110000,
	12: 120000,
	13: 130000,
	14: 140000,
	15: 150000,
};
export const getKeyByValue = (object, value): number =>
	Number(Object.entries(object).find(([key, val]) => val === value)?.[0]);
