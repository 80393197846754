import EducationBookIcon from "assets/svgs/EducationBookIcon";
import FilterIcon from "assets/svgs/FilterIcon";
import FolderIcon from "assets/svgs/FolderIcon";
import LanguageIcon from "assets/svgs/LanguageIcon";
import LocationIconOutline from "assets/svgs/LocationIconOutline";
import MailIconOutlined from "assets/svgs/MailIconOutlined";
import PhoneIcon from "assets/svgs/PhoneIcon";
import ProfileIcon from "assets/svgs/ProfileIcon";
import SearchIconOutline from "assets/svgs/SearchIconOutline";
import StarExperienceIcon from "assets/svgs/StarExperienceIcon";
import SuitcaseIconV2 from "assets/svgs/SuitcaseIconV2";

export const SECTION_MAP = {
	OVERALL: "Overall",
	PROFESSIONAL_DETAILS: "Professional Details",
	PERSONAL_DETAILS: "Personal Details",
	JOB_DETAILS: "Job Details",
	CONTACT_DETAILS: "Contact Details",
	PROFILE_EDITOR: "Editor Details",
	EDIT_SKILLS: "Edit Skills",
};

export const SUB_SECTION_MAP = {
	EXPERIENCE: {
		sectionName: "Experience",
		sectionIcon: SuitcaseIconV2,
	},
	EDUCATION: {
		sectionName: "Education",
		sectionIcon: EducationBookIcon,
	},
	SKILLS: {
		sectionName: "Skills",
		sectionIcon: StarExperienceIcon,
	},
	BASIC_DETAILS: {
		sectionName: "Basic details",
		sectionIcon: ProfileIcon,
	},
	LANGUAGES_KNOWN: {
		sectionName: "Languages known",
		sectionIcon: LanguageIcon,
	},
	LANGUAGES_EVALUATION: {
		sectionName: "Language evaluations",
		sectionIcon: LanguageIcon,
	},
	JOB_PREFERENCES: {
		sectionName: "Job preferences",
		sectionIcon: FilterIcon,
	},
	JOB_TYPES: {
		sectionName: "Job types",
		sectionIcon: SearchIconOutline,
	},
	PREFERRED_JOB_TITLE: {
		sectionName: "Preferred Job Title",
		sectionIcon: SearchIconOutline,
	},
	LOCATION: {
		sectionName: "Location",
		sectionIcon: LocationIconOutline,
	},
	DOCUMENTS: {
		sectionName: "Documents and Assets",
		sectionIcon: FolderIcon,
	},
	EMAIL: {
		sectionName: "Email ID",
		sectionIcon: MailIconOutlined,
	},
	MOBILE: {
		sectionName: "Mobile Number",
		sectionIcon: PhoneIcon,
	},
};

export const LANGUAGE_PROFICIENCY_LABEL_MAP = {
	0: "No English",
	1: "Basic",
	5: "Intermediate",
	10: "Advanced",
};

export const MAX_EXPERIENCE_COUNT_ALLOWED = 10;
export const MAX_EDUCATION_COUNT_ALLOWED = 10;
