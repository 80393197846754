import React from "react";

function HomeNavbarFillIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			fill="none"
			viewBox="0 0 25 24"
		>
			<path
				fill="#1F8268"
				d="M14.676 20.8v-6.005h-4.348v6.004c0 .66-.489 1.201-1.086 1.201H5.98c-.598 0-1.087-.54-1.087-1.2v-8.407H3.046c-.5 0-.74-.684-.359-1.045l9.087-9.042a1.02 1.02 0 011.457 0l9.087 9.043c.37.36.14 1.044-.36 1.044h-1.847V20.8c0 .66-.49 1.201-1.087 1.201h-3.26c-.599 0-1.088-.54-1.088-1.2z"
			/>
		</svg>
	);
}

export default HomeNavbarFillIcon;
