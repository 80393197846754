/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const PhoneIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
		<path
			stroke="#4D3951"
			strokeWidth={1.5}
			d="M6.62 10.915c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25 1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1v3.5a1 1 0 0 1-1 1 17 17 0 0 1-17-17 1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2Z"
		/>
	</svg>
);
export default PhoneIcon;
