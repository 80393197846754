import * as React from "react";

const EducationBookIcon = (props) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<svg width={19} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m1 1 8.421 3.368V17L1 13.632V1ZM17.842 1l-8.42 3.368V17l8.42-3.368V1Z"
			fill="#fff"
			stroke="#4D3951"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.737 4.368h3.368v10.948a1.684 1.684 0 1 1-3.368 0V4.368Z"
			fill="#fff"
			stroke="#4D3951"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
	</svg>
);

export default EducationBookIcon;
