/* eslint-disable consistent-return */
import { SESSION_STORAGE_KEYS } from "utils/constants";
import {
	ALLOWED_OTP_VERIFY_ATTEMPTS,
	KEY_MOBILE_NUMBER,
	OTP_REATTEMPT_TIMEOUT,
	OTP_RESEND_TIMEOUT,
} from "../constants";

type NumberDetails = {
	mobileNumber: string;
	otpSentAt: number;
	verificationAttemptsMade: number;
	lastVerificationAttemptAt?: number;
	otpSendTries?: number;
};

export const saveNumberDetails = (arg: NumberDetails) => {
	sessionStorage.setItem(KEY_MOBILE_NUMBER, arg.mobileNumber);
	sessionStorage.setItem(arg.mobileNumber, JSON.stringify(arg));
};

/** Get details of the number that was last saved */
export const getLastSavedNumberDetails = (): NumberDetails | undefined => {
	try {
		const number = sessionStorage.getItem(KEY_MOBILE_NUMBER);
		if (!number) {
			return;
		}
		const dataStr = sessionStorage.getItem(number);
		if (!dataStr) {
			return;
		}

		return JSON.parse(dataStr);
	} catch (e) {
		console.error(e);
	}
};

/** Returns the number of verification attempts left for last saved mobile number */
export const getRemainingVerificationAttempts = (): number => {
	const data = getLastSavedNumberDetails();
	if (!data) return 0;
	const attemptsDone = data.verificationAttemptsMade || 0;
	return ALLOWED_OTP_VERIFY_ATTEMPTS - attemptsDone;
};

/** Return the time (in seconds) after which the OTP should be resend */
export const getOtpResendTimeout = () => {
	const data = getLastSavedNumberDetails();
	if (!data) return OTP_RESEND_TIMEOUT;
	const lastSentAt = data.otpSentAt;
	const timeElapsed = (Date.now() - lastSentAt) / 1000;
	const timeElapsedSinceLastOtp = Math.trunc(timeElapsed);

	if (timeElapsedSinceLastOtp > OTP_RESEND_TIMEOUT) {
		return OTP_RESEND_TIMEOUT;
	}
	return OTP_RESEND_TIMEOUT - timeElapsedSinceLastOtp;
};

/** Returns the time (in seconds) after which the user should be allowed to verify OTP again
 * after he was blocked from verification */
export const getOtpReattemptTimeout = () => {
	const data = getLastSavedNumberDetails();
	if (!data) return OTP_REATTEMPT_TIMEOUT;

	const now = Date.now();
	// If last attempt is not present, assume last attempt was a day ago
	const lastAttemptAt = data.lastVerificationAttemptAt || now - 24 * 60 * 60 * 1000;
	const timeElapsed = (now - lastAttemptAt) / 1000;
	const timeElapsedSinceLastAttempt = Math.trunc(timeElapsed);

	if (timeElapsedSinceLastAttempt > OTP_REATTEMPT_TIMEOUT) return OTP_REATTEMPT_TIMEOUT;
	return OTP_REATTEMPT_TIMEOUT - timeElapsedSinceLastAttempt;
};

export const add91 = (number: string) => {
	if (number.length === 10) return `91${number}`;
	return number;
};

export const remove91 = (number: string) => {
	if (number.length > 10 && number.startsWith("91"))
		return number.substring(number.length - 10);
	return number;
};

export const experiment2Titles = [
	"500+ candidates landed a job with this resume",
	"Template verified by 100+ employers",
	"Can be downloaded/ shared as PDF",
];

export const TokenUtils = {
	key: "__token__",
	getToken() {
		return localStorage.getItem(TokenUtils.key);
	},
	setToken(token: string) {
		localStorage.setItem(TokenUtils.key, token);
	},
	removeToken() {
		localStorage.removeItem(TokenUtils.key);
	},
};

export const getModifiedOnboardingFlowType = (onboardingFlowType: string) => {
	if (onboardingFlowType !== "apnaCanvas") {
		return onboardingFlowType;
	}
	const canvasCompanyFlowType = sessionStorage.getItem(
		SESSION_STORAGE_KEYS.CANVAS_COMPANY_FLOW_TYPE
	);
	return canvasCompanyFlowType;
};
