import React from "react";

function CommunityNavbarFillIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			fill="none"
			viewBox="0 0 25 24"
		>
			<path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
			<circle
				cx="8.5"
				cy="10"
				r="3"
				fill="#1F8268"
				stroke="#fff"
				strokeWidth="1.5"
			/>
			<path
				fill="#1F8268"
				stroke="#fff"
				strokeWidth="1.5"
				d="M8.209 14C4.734 14 2.5 16.538 2.5 19.67A2.33 2.33 0 004.83 22h7.34a2.33 2.33 0 002.33-2.33c0-3.132-2.817-5.67-6.291-5.67z"
			/>
			<path
				fill="#1F8268"
				stroke="#fff"
				strokeWidth="1.5"
				d="M15.773 12C11.756 12 8.5 15.173 8.5 19.087A2.913 2.913 0 0011.413 22h8.174a2.913 2.913 0 002.913-2.913c0-3.914-2.71-7.087-6.727-7.087z"
			/>
			<circle
				cx="15.45"
				cy="7"
				r="4"
				fill="#1F8268"
				stroke="#fff"
				strokeWidth="1.5"
			/>
		</svg>
	);
}

export default CommunityNavbarFillIcon;
