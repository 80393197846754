/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import QueriesKeys from "constants/queryKeys";
import { useQuery } from "react-query";
import { getCandidateConfiguration } from "utils/apiClientPrivate";

export const useCandidateConfiguration = () =>
	useQuery<{
		targetOnboardingRootRoute: string;
	}>(
		QueriesKeys.GET_CANDIDATE_CONFIG,
		async () => {
			try {
				const { data } = await getCandidateConfiguration();
				return {
					targetOnboardingRootRoute: data?.data_collection_onboarding
						?.is_onb_v3_web_enabled
						? "candidate-enrichment"
						: "candidate-login",
				};
			} catch {
				return {
					targetOnboardingRootRoute: "candidate-enrichment",
				};
			}
		},
		{
			enabled: false,
			retry: false,
		}
	);
