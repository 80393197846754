import React from "react";

function JobsNavbarFillIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			fill="none"
			viewBox="0 0 25 24"
		>
			<path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
			<path
				fill="#1F8268"
				d="M2.5 8.5a3 3 0 013-3h14.003a3 3 0 013 3v9.669a3 3 0 01-3 3H5.5a3 3 0 01-3-3V8.5z"
			/>
			<path
				stroke="#EAF8F4"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M4.52 6.813l2.23 5.441a2 2 0 001.85 1.242h7.915a2 2 0 001.86-1.265l2.14-5.418"
			/>
			<path
				stroke="#1F8268"
				strokeWidth="1.5"
				d="M15.6 3H9.4a1.9 1.9 0 00-1.9 1.9v1.698c0 .222.18.402.402.402h9.196c.222 0 .402-.18.402-.402V4.9A1.9 1.9 0 0015.6 3z"
			/>
		</svg>
	);
}

export default JobsNavbarFillIcon;
