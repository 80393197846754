/* eslint-disable prettier/prettier */
export const SVIP_TITLE = "Silicon Valley Immersion Program";
export const SVIP_DES =
    "Apna is launching the Silicon Valley Immersion Program, a creativity and problem-solving competition for aspirants like YOU! This is your chance to stand out from the crowd and take a step closer to your dream career, no matter your background! It is your launchpad to dream big and achieve bigger.";
export const DESKTOP_BANNER = "https://storage.googleapis.com/mumbai_apnatime_prod/silicon_valley/Silicon-Valley-Immersion-desktop.png"
export const MOBILE_BANNER = "https://storage.googleapis.com/mumbai_apnatime_prod/silicon_valley/Silicon-Valley-Immersion-mobile.png"
export const DESKTOP_BANNER_LOGGED_OUT = "https://storage.googleapis.com/mumbai_apnatime_prod/silicon_valley/Silicon%20Valley%20Immersion%20Program%20Web%20Non%20Logged.png"
export const MOBILE_BANNER_LOGGED_OUT = "https://storage.googleapis.com/mumbai_apnatime_prod/silicon_valley/Silicon%20Valley%20Immersion%20Program%20mobile%20Non%20Logged.png.png"
export const DESKTOP_BANNER_REGISTERED = "https://storage.googleapis.com/mumbai_apnatime_prod/silicon_valley/SVIP%20registeredweb.png"
export const MOBILE_BANNER_REGISTERED = "https://storage.googleapis.com/mumbai_apnatime_prod/silicon_valley/SVIP%20registeredmob.png"
export const MOBILE_BANNER_NO_CTA = "https://storage.googleapis.com/mumbai_apnatime_prod/silicon_valley/Silicon-Valley-Immersion-mobile-nobanner.png"
export const DEKSTOP_BANNER_NO_CTA = "https://storage.googleapis.com/mumbai_apnatime_prod/silicon_valley/Silicon-Valley-Immersion-desktop-nobanner.png"


export const USER_JOURNEY_BE_EVENT_NAME_MAPING = {
    VIEW: "svip_page_visit",
    REGISTER: "svip_register_clicked_1",
    REGISTER_NOW: "svip_register_clicked_2"
}

export const SVIP_LOCAL_STROAGE = {
    AUTH_STARTED: "AUTH_STARTED",

}


export const parseSvipUserRoundDetails = (data: any) => ({
    roundeOne: {
        status: data?.round_one_details?.status,
        boxColor: data?.round_one_details?.box_color,
        boxText: data?.round_one_details?.box_text,
        ctaText: data?.round_one_details?.cta_text,
        isRoundActive: data?.round_one_details?.is_active,
        ctaDisabled: data?.round_one_details?.is_cta_disabled,
        ctaHidden: data?.round_one_details?.is_cta_hidden,
        examUrl: data?.round_one_details?.exam_sso_url,
        fallback: data?.round_one_details?.fallback,
        results: {
            title: data?.round_one_details?.result?.title,
            subTitle: data?.round_one_details?.result?.sub_title,
        }
    },
    roundTwo: {
        status: data?.round_two_details?.status,
        boxColor: data?.round_two_details?.box_color,
        boxText: data?.round_two_details?.box_text,
        ctaText: data?.round_two_details?.cta_text,
        isRoundActive: data?.round_two_details?.is_active,
        ctaDisabled: data?.round_two_details?.is_cta_disabled,
        ctaHidden: data?.round_two_details?.is_cta_hidden,
        examUrl: data?.round_two_details?.exam_sso_url,
        fallback: data?.round_two_details?.fallback,
        results: {
            title: data?.round_two_details?.result?.title,
            subTitle: data?.round_two_details?.result?.sub_title,
        }
    }
})

export const parseSVIPRoundTwoDetails = (data: any) => ({
    isEligible: data?.is_eligible,
    description: data?.description,
    isPrimaryCtaActive: data?.is_primary_cta_active || false,
    submission: {
        isSubmitted: data?.submission?.is_submitted,
        submittedKey: data?.submission?.problem_statement_key,
        submissionDocUrl: data?.submission?.document_url,
        subMissionSubject: data?.submission?.problem_statement_value,
        lastUploadTime: data?.submission?.last_uploaded_at
    },
    faqs: data?.faqs,

    problemStatements: data?.problem_statements?.map((ps) => ({
        title: ps?.title,
        subtitle: ps?.subtitle,
        docUrl: ps?.document_url,
        key: ps?.key
    }))
})

export const maxSVIPFileSizeInBytes = 20 * 1024 * 1024;

export const validFileTypesForSVIPRoundTwo = [
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/pdf', // .pdf
    'application/zip', // .zip
];
