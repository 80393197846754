/* eslint-disable consistent-return */
import styled, { css, keyframes } from "styled-components";
import { isHostLaunchedMicrosite } from "utils";
import { MICROSITES } from "utils/constants";
import { bp, bpMax, heightMax } from "./mediaQuery";

declare module "styled-components" {
	interface DefaultTheme extends ThemeBase {}
}

export const VENDORS_CONSTANT = ["apna", "apnaV2", "vi"] as const;

export type Vendors = (typeof VENDORS_CONSTANT)[number];

type PartialRecord<K extends Vendors, T> = {
	[P in K]?: T;
};

type StylesRecord = PartialRecord<Vendors, ReturnType<typeof css>>;

export const createStyleVariants = (styles: StylesRecord) => (vendor: Vendors) =>
	styles[vendor];

// TODO-> Improve types later- Assigned to Shyam
// export function createNestedStyledVariants<
// 	T extends PartialRecord<Vendors, Record<string, ReturnType<typeof css>>>
// >(styles: T) {
// 	return <VendorKey extends Vendors>(vendor: VendorKey) =>
// 		<VariantKey extends keyof T[VendorKey]>(variant: VariantKey) =>
// 			styles[vendor][variant];
// }

export function createNestedStyledVariants<
	T extends PartialRecord<Vendors, Record<string, ReturnType<typeof css>>>
>(styles: T) {
	return <VendorKey extends Vendors>(vendor: VendorKey) =>
		(variant: any) =>
			styles?.[vendor]?.[variant];
}

// example on how to use create nested style

// const nestedStyles = {
// 	apna: {
// 		primary: css``,
// 		seconday: css``,
// 	},
// 	vi: {
// 		primary: css``,
// 	},
// };

// const nestedStylesVariants = createNestedStyledVariants(nestedStyles)("vi")("primary");

export const getGlobalCSSVariants = (availableServerHost?: string) => {
	const hostVendor = availableServerHost || isHostLaunchedMicrosite();
	if (hostVendor) {
		if (hostVendor === MICROSITES.VI) {
			return {
				primaryColor: "#EE2737",
				primarySelectionColor: "#FFC600",
				primaryChipSelectionColor: "#FFC600",
				primaryChipSelectionBorderColor: "#FFC600",
				primaryChipTextColor: "#000000DE",
				primaryColorWithOpacity: "#f2d5d6",
			};
		}
	} else {
		return {
			primaryColor: "#1F8268",
			primarySelectionColor: "#4D3951",
			primaryChipTextColor: "#FFFFFF",
			primaryChipSelectionColor: "#4D3951",
			primaryChipSelectionBorderColor: "#827485",
			primaryColorWithOpacity: "#EAF8F4",
		};
	}
};

export const getGlobalFontFamily = () => {
	const hostVendor = isHostLaunchedMicrosite();
	if (hostVendor) {
		if (hostVendor === MICROSITES.VI) {
			return {
				fontFamily: `Vi`,
			};
		}
	} else {
		return {
			fontFamily: `"Inter", "Roboto Flex"`,
		};
	}
};

export const getGlobalCSSVariantTextFieldBorder = () => {
	const hostVendor = isHostLaunchedMicrosite();
	if (hostVendor) {
		if (hostVendor === MICROSITES.VI) {
			return {
				primaryColor: "#FFC600",
			};
		}
	} else {
		return {
			primaryColor: "#1F8268",
		};
	}
};

export const getGlobalCSSVariantSelectBorderBg = () => {
	const hostVendor = isHostLaunchedMicrosite();
	if (hostVendor) {
		if (hostVendor === MICROSITES.VI) {
			return {
				primarySelectBorder: "transparent",
				primarySelectionBg: "#F9E8D0",
			};
		}
	} else {
		return {
			primarySelectBorder: "#1F8268",
			primarySelectionBg: "transparent",
		};
	}
};

export const getGlobalCSSVariantCheckBox = (availableServerHost?: string) => {
	const hostVendor = availableServerHost || isHostLaunchedMicrosite();
	if (hostVendor) {
		if (hostVendor === MICROSITES.VI) {
			return {
				primarySelectBorder: "transparent",
				primarySelectionBg: "#F9E8D0",
				primarySelectedRadioBtnBg: "#FFC600",
			};
		}
	} else {
		return {
			primarySelectBorder: "#1F8268",
			primarySelectionBg: "transparent",
			primarySelectedRadioBtnBg: "#1F8268",
		};
	}
};
interface FontSize {
	xs: string;
	sm: string;
	base: string;
	lg: string;
	xl: string;
	"2xl": string;
	"3xl": string;
	"4xl": string;
}

interface FontWeight {
	normal: number;
	medium: number;
	demiBold: number;
	semiBold: number;
	bold: number;
	black: number;
}

export interface ThemeBase {
	vendor: Vendors;
	colors: {
		backgroundPrimary: string;
		primary: string;
		tertiary?: string;
		gray: {
			100?: string;
			200?: string;
			300?: string;
			400?: string;
			500?: string;
			600?: string;
			700?: string;
			800?: string;
			900?: string;
			1000?: string;
		};
		secondary: string;
		primaryBlack: string;
		warning?: string;
		categoryCard?: string;
		checkBox?: string;
	};
	fontSize: FontSize;
	fontWeight: FontWeight;
	fontFamily: string;
}

interface Theme {
	apna: ThemeBase;
	apnaV2: ThemeBase;
	vi: ThemeBase;
}

const baseFontSizes: FontSize = {
	xs: "0.75rem",
	sm: "0.875rem",
	base: "1rem",
	lg: "1.125rem",
	xl: "1.25rem",
	"2xl": "1.5rem",
	"3xl": "1.875rem",
	"4xl": "2.25rem",
};

const baseFontWeights: FontWeight = {
	normal: 400,
	medium: 500,
	demiBold: 600,
	semiBold: 700,
	bold: 800,
	black: 900,
};

export const theme: Theme = {
	apna: {
		vendor: "apna",
		colors: {
			backgroundPrimary: "#fff",
			primary: "#4D3951",
			tertiary: "#1F8268",
			gray: {
				100: "#F5F7FB",
				200: "#DAE0E5",
				300: "#DAE0E5",
				600: "#6B7C8F",
				700: "#536579",
				800: "#31445A",
				900: "#8C8594",
				1000: "#F4F2F6",
			},
			secondary: "#FFD166",
			primaryBlack: "#444444",
			warning: "#FC9F5B",
			categoryCard: "#F9E8D0",
			checkBox: "#2BB792",
		},
		fontSize: {
			...baseFontSizes,
		},
		fontWeight: {
			...baseFontWeights,
		},
		fontFamily: `"Inter", "Roboto Flex"`,
	},
	apnaV2: {
		vendor: "apnaV2",
		colors: {
			backgroundPrimary: "#F0EBF1",
			primary: "#4D3951",
			tertiary: "#2BB792",
			gray: {
				100: "#F5F7FB",
				200: "#DAE0E5",
				300: "#DAE0E5",
				600: "#6B7C8F",
				700: "#536579",
				800: "#31445A",
				900: "#8C8594",
				1000: "#F4F2F6",
			},
			secondary: "#FFD166",
			primaryBlack: "#444444",
			warning: "#FC9F5B",
			categoryCard: "#F9E8D0",
			checkBox: "#2BB792",
		},
		fontSize: {
			...baseFontSizes,
		},
		fontWeight: {
			...baseFontWeights,
		},
		fontFamily: `"Inter","Roboto Flex"`,
	},
	vi: {
		vendor: "vi",
		colors: {
			backgroundPrimary: "#fff",
			primary: "#4D3951",
			tertiary: "#E72938",
			gray: {
				100: "#F4F4F4",
				200: "#EAEAEC",
				300: "#D5D6D9",
				600: "#82838E",
				700: "#797A86",
				800: "#595969",
			},
			secondary: "#FFC600",
			primaryBlack: "#2f3043",
			warning: "#FA9810",
			categoryCard: "#F9E8D0",
			checkBox: "#FFC600",
		},
		fontSize: {
			...baseFontSizes,
		},
		fontWeight: {
			...baseFontWeights,
		},
		// fontFamily: `-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
		fontFamily: `Vi`,
	},
};

export { styled, css, keyframes, bp, bpMax, heightMax };
