import { useRouter } from "next/router";
import { useState, useEffect } from "react";

const useSafePush = () => {
	const router = useRouter();
	const [isPathChanging, setIsPathChanging] = useState(false);
	const onPathChangeComplete = () => {
		setIsPathChanging(false);
	};

	const safePush = (path, ...args) => {
		if (isPathChanging) {
			return;
		}
		setIsPathChanging(true);
		router.replace(path, ...args);
	};

	useEffect(() => {
		router.events.on("routeChangeComplete", onPathChangeComplete);

		return () => {
			router.events.off("routeChangeComplete", onPathChangeComplete);
		};
	}, [router]);
	return { safePush };
};

export default useSafePush;
