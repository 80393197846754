/* eslint-disable prettier/prettier */
const QueriesKeys = {
	GET_CITIES: "GET_CITIES",
	GET_LOCATIONS: "GET_LOCATIONS",
	GET_EDUCATIONS: "GET_EDUCATIONS",
	GET_JOBS: "GET_JOBS",
	GET_CANDIDATE_DETAILS: "GET_CANDIDATE_DETAILS",
	GET_CANDIDATE_PROFILE: "GET_CANDIDATE_PROFILE",
	GET_CANDIDATE_JOB_DETAILS: "GET_CANDIDATE_JOB_DETAILS",
	GET_OLD_PROFILE: "GET_OLD_PROFILE",
	GET_PROFILE_COMPLETION_PROGRESS: "GET_PROFILE_COMPLETION_PROGRESS",
	GET_PRIVATE_JOB_DETAILS: "GET_PRIVATE_JOB_DETAILS",
	GET_CANDIDATE_ELIGIBILITY: "GET_CANDIDATE_ELIGIBILITY",
	IS_PREFERRED_JOB_TYPES_V2_ENABLED: "IS_PREFERRED_JOB_TYPES_V2_ENABLED",
	GET_CANDIDATE_WEB_APPLICATIONS: "GET_CANIDATE_WEB_APPLICATIONS",
	GET_CANDIDATE_ONBOARDING_COMPLETE: "GET_CANDIDATE_ONBOARDING_COMPLETE",
	EDIT_LANGUAGES_KNOWN: "EDIT_LANGUAGES_KNOWN",
	WORK_TYPE_PREFERENCE: "WORK_TYPE_PREFERENCE",
	GET_JOB_TYPES: "GET_JOB_TYPES",
	SEARCH_JOB_TYPES: "SEARCH_JOB_TYPES",
	PUT_JOB_TYPES: "PUT_JOB_TYPES",
	GET_DOCUMENT_ASSET: "GET_DOCUMENT_ASSET",
	GET_JOB_CITIES: "GET_JOB_CITIES",
	GET_CITY_LIVE_NONLIVE_SUGGESTION: "GET_CITY_LIVE_NONLIVE_SUGGESTION",
	GET_NEAREST_LOCATION: "GET_NEAREST_LOCATION",
	GET_AREA_SUGGESTION: "get_area_suggestion",
	SUGGESTED_JOB_CITIES: "suggested_job_cities",
	GET_HOMETOWN_SUGGESTION: "get_hometown_suggestion",
	UPDATE_ENRICHED_PROFILE: "update-enriched-profile",
	VERIFY_EMAIL_OTP_REQUEST: "verify-email-otp-request",
	VERIFY_EMAIL_OTP: "verify-email-otp",
	GET_SUGGESTION_SKILLS: "GET_SUGGESTION_SKILLS",
	UPDATE_PROFILE_SKILLS: "UPDATE_PROFILE_SKILLS",
	PREVIEW_DOCUMENT: "PREVIEW_DOCUMENT",
	SEARCH_JOB_TITLE: "SEARCH_JOB_TITLE",
	GET_TOP_RECOMMENDED_JOB_TITLE: "GET_TOP_RECOMMENDED_JOB_TITLE",
	UPDATE_JOB_TITLE: "UPDATE_JOB_TITLE",
	GET_LANGUAGE_LIST: "GET_LANGUAGE_LIST",
	GET_EVALUATION_SOURCE: "GET_EVALUATION_SOURCE",
	GET_CANDIDATE_CONFIG: "GET_CANDIDATE_CONFIG",
	GET_NB_NEAREST_LOCATION: "GET_NB_NEAREST_LOCATION",
	GET_NB_LOCATION_SEARCH: "GET_NB_LOCATION_SEARCH",
	GET_NB_AREA_SEARCH: "GET_NB_AREA_SEARCH",
	SUGGESTED_NB_JOB_CITIES: "SUGGESTED_NB_JOB_CITIES",
	PREFERRED_NB_JOB_CITIES: "PREFERRED_NB_JOB_CITIES",
	GET_ECC_SCREEN_DATA: "GET_ECC_SCREEN_DATA",
	GET_CANVAS_CHALLENGE_HIGHLIGHTS: "GET_CANVAS_CHALLENGE_HIGHLIGHTS",
	GET_CANVAS_CHALLENGE_ROUND_STATES: "GET_CANVAS_CHALLENGE_ROUND_STATES",
	GET_CANVAS_CHALLENGE_LEARNING_HUB_STATES: "GET_CANVAS_CHALLENGE_LEARNING_HUB_STATES",
	GET_CANVAS_TEAM_DETAILS: "GET_CANVAS_TEAM_DETAILS",
	GET_CANVAS_AVAILABLE_TEAMS: "GET_CANVAS_AVAILABLE_TEAMS",
	GET_CANVAS_AVAILABLE_INDIVIDUALS: "GET_CANVAS_AVAILABLE_INDIVIDUALS",
	GET_SKILL_SUGGESTION: "GET_SKILL_SUGGESTION",
	DELETE_USER_ACCOUNT: "DELETE_USER_ACCOUNT",
	UPDATE_PROFILE_SKILLS_CERTIFICATE: "UPDATE_PROFILE_SKILLS_CERTIFICATE",
	GET_SKILL_CERTIFICATE: "GET_SKILL_CERTIFICATE",
	MAPPED_DEPARTMENT_LIST: "MAPPED_DEPARTMENT_LIST",
	GET_NEW_LABEL_CONFIG: "GET_NEW_LABEL_CONFIG",
};

export default QueriesKeys;
