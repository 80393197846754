import { CITIES_STATUS } from "constants/job";
import { da } from "date-fns/locale";
import { LocationIds, LocationType, NBLocationDetails, NBLocationSearchData, NearestLocationResponseType, UserLocation } from "modules/candidate/profile/types/UserProfileDataType";

// eslint-disable-next-line import/prefer-default-export
export function isEmpty(arg) {
	if (Array.isArray(arg)) {
		return arg.length === 0;
	}
	if (typeof arg === "object") {
		return Object.keys(arg).length === 0;
	}
	return false;
}

export const removeHash = () => {
	const uri = window.location.toString();

	if (uri.indexOf("#") > 0) {
		// eslint-disable-next-line no-restricted-globals
		history.back();
	}
};

export const getNotNullValueKeys = (arryObj: LocationIds | undefined) => {
    const filteredObject: { [x: string]: null | string | undefined } = {};
    for (const key in arryObj) {
        const value = arryObj[key];
        if (value !== null && typeof value !== "boolean") {
            filteredObject[key] = value;
        }
    }
    return filteredObject;
};

export const convertToNBLocationDetailFormat = (
	inputData: any,
	inputType: "UserLocation" | "NBSearchData" | "NBGPSData",
): NBLocationDetails | null => {
	if(!inputData) return null;
	/**
	 * inputType === "UserLocation"
	 */
	if(inputType === "UserLocation"){
		const data = inputData as UserLocation;
	if (!data || (!data?.city && !data.district && !data.sub_district)) {
		return null;
	}
	const result: NBLocationDetails = {
		location_ids: {
			city_id: data.city?.id,
			area_id: data.area?.id,
			sub_district_id: data?.sub_district?.id,
		},
		cityStatus: data?.city?.is_live ? CITIES_STATUS.LIVE : CITIES_STATUS.NON_LIVE,
		displayName: data.display_name || "",
		cityName: data?.city?.name,
		areaName: data?.area?.name,
		subDistrictName: data?.sub_district?.name
	};
	return result;
	} 
	/**
	 * inputType === "NBSearchData"
	 */
	else if (inputType === "NBSearchData") {
		const data = inputData as NBLocationSearchData;
		return {
			location_ids: data?.location_ids,
			cityStatus: data?.city?.status,
			displayName: data?.name || "",
			cityName: data?.city?.name,
			areaName: data?.area?.name,
			subDistrictName: data?.sub_district?.name
		};
	}
	/**
	 * inputType === "NBGPSData"
	 */
	 else if(inputType === "NBGPSData"){
		const data = inputData as NearestLocationResponseType;
		return {
			displayName: data?.display_name,
			location_ids: {
				city_id: data?.city?.id,
				area_id: data?.area?.id,
				sub_district_id: data?.subdistrict?.id
			},
			cityStatus: data?.city?.is_live ? CITIES_STATUS.LIVE : CITIES_STATUS.NON_LIVE,
			cityName: data?.city?.name,
			areaName: data?.area?.name,
			subDistrictName: data?.subdistrict?.name
		}
	}
	
};
