/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextField, TextFieldProps, makeStyles, InputAdornment } from "@material-ui/core";
import AddIconProfileEditor from "assets/svgs/AddIconProfileEditor";
import InfoIconLightProfileEditor from "assets/svgs/InfoIconLightProfileEditor";
import React, { FC, ReactNode } from "react";
import LockIcon from "assets/svgs/LockIcon";
import { getGlobalCSSVariantTextFieldBorder } from "theme";
import ErrorLabel from "./datepicker/ErrorLabel";

interface InputFieldPropTypes {
	errorText?: string | ReactNode;
	helperIcon?: JSX.Element;
	errorIcon?: JSX.Element;
	helperTextIconType?: "lock" | "info" | "star";
	disable?: boolean;
	max?: number;
}

const cssVariant = getGlobalCSSVariantTextFieldBorder();

const useStyles = makeStyles((theme) => ({
	customTextField: {
		height: "40px",
		"& .MuiInputBase-root": {
			lineHeight: "20px",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: cssVariant.primaryColor, // Set custom border color
			// borderWidth: "1px",
		},
		"& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
			borderColor: "#f44336", // Set custom border color
		},
		"& .MuiInputBase-input": {
			fontSize: "14px",
			"&::placeholder": {
				fontSize: "14px",
			},
		},
		"& .MuiInputAdornment-positionEnd": {
			position: "absolute",
			right: "10px",
		},
		"& .MuiOutlinedInput-inputMarginDense": {
			padding: "10px 12px auto 0",
		},
	},
}));

export const RenderOption = ({ optionName, icon, ...props }: any) => (
	<div className="w-full">
		{optionName?.includes("+ Add:") ? (
			<div className="text-[14px] leading-[20px] text-[#190A28] font-bold">
				<li {...props} className="flex items-center">
					<AddIconProfileEditor fill="#190A28" style={{ marginRight: "2px" }} />
					{`Add ${optionName.replace("+ Add:", "")}`}
				</li>
			</div>
		) : optionName?.includes("Please select") ? (
			<div className="border-b border-dashed border-[#D1CED4] pb-2">
				<li {...props} className=" flex items-center">
					<InfoIconLightProfileEditor />{" "}
					<span className="text-onSurface-secondary-inverse ml-2 text-xs text-[#8C8594]">
						{optionName}
					</span>
				</li>
			</div>
		) : (
			<li {...props} className="text-[14px] leading-[20px] flex items-center">
				{
					icon && (
						<div className="h-[32px] w-[32px] flex items-center justify-center mr-2">{icon}</div>
					)
				}
				{optionName}
			</li>
		)}
	</div>
);

const InputField: FC<InputFieldPropTypes & TextFieldProps> = React.forwardRef(
	(
		{
			placeholder = "e.g.  software developer",
			label = "",
			error,
			errorText,
			helperText = "",
			helperIcon = null,
			errorIcon = null,
			helperTextIconType = "star",
			disable,
			...props
		},
		ref
	) => {
		const classes = useStyles();
		return (
			<div className="flex flex-col gap-1">
				<TextField
					{...props}
					ref={ref}
					placeholder={placeholder}
					error={error}
					autoComplete="off"
					size="small"
					variant="outlined"
					className={classes.customTextField}
					disabled={disable}
				/>

				{error && errorText && (
					<div className="mt-1">
						<ErrorLabel type="error" message={errorText} />
					</div>
				)}
			</div>
		);
	}
);

export default InputField;
