import ErrorIcon from "assets/svgs/ErrorMessageIcon";
import { FC, ReactNode } from "react";

type Props = {
	icon?: ReactNode;
	message: string | ReactNode;
	error: boolean;
};

const ErrorMessage: FC<Props> = ({
	icon = <ErrorIcon />,
	message = "",
	error = false,
}) => {
	return error ? (
		<div className="flex items-center gap-[4px] text-[12px] text-[#CC0000]">
			{icon}
			{message}
		</div>
	) : null;
};

export default ErrorMessage;
