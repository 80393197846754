import { useEffect, useRef } from "react";
import TagManager from "react-gtm-module";
import config from "../config";

const useGTM = () => {
	const initializedRef = useRef(false);

	useEffect(() => {
		const handleInteraction = () => {
			if (!initializedRef.current) {
				TagManager.initialize({
					gtmId: config.google.tagManager,
					dataLayerName: "PageDataLayer",
				});
				initializedRef.current = true;
			}

			// Optionally remove event listeners after the first interaction
			document.removeEventListener("click", handleInteraction);
			document.removeEventListener("keydown", handleInteraction);
			document.removeEventListener("mousemove", handleInteraction);
			document.removeEventListener("touchstart", handleInteraction);
		};

		document.addEventListener("click", handleInteraction);
		document.addEventListener("keydown", handleInteraction);
		document.addEventListener("mousemove", handleInteraction);
		document.addEventListener("touchstart", handleInteraction);

		return () => {
			document.removeEventListener("click", handleInteraction);
			document.removeEventListener("keydown", handleInteraction);
			document.removeEventListener("mousemove", handleInteraction);
			document.removeEventListener("touchstart", handleInteraction);
		};
	}, []);
};

const GtmInitializer = () => {
	useGTM();

	return null;
};

export default GtmInitializer;
