/* eslint-disable react/jsx-props-no-spreading */
const SilverBadge = (props) => (
	<svg width={111} height={32} viewBox="0 0 111 32" {...props}>
		<g fill="none" fillRule="evenodd">
			<path
				fill="#6A6A6A"
				d="M24.692 4h55.616c2.675 0 3.645.278 4.623.801a5.456 5.456 0 012.268 2.268c.523.978.801 1.948.801 4.623v8.616c0 2.675-.278 3.645-.801 4.623a5.456 5.456 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H24.692c-2.675 0-3.645-.278-4.623-.801a5.456 5.456 0 01-2.268-2.268c-.523-.978-.801-1.948-.801-4.623v-8.616c0-2.675.278-3.645.801-4.623a5.456 5.456 0 012.268-2.268C21.047 4.278 22.017 4 24.692 4z"
			/>
			<path
				fill="#FFF"
				fillRule="nonzero"
				d="M44.264 20.204c.68 0 1.268-.11 1.764-.33.496-.22.876-.528 1.14-.924a2.42 2.42 0 00.396-1.374c0-.728-.2-1.29-.6-1.686-.4-.396-1.024-.73-1.872-1.002a9.387 9.387 0 01-1.008-.372c-.224-.104-.38-.214-.468-.33a.724.724 0 01-.132-.45c0-.264.1-.466.3-.606.2-.14.472-.21.816-.21.648 0 1.256.232 1.824.696l.912-1.068a3.73 3.73 0 00-1.254-.804c-.46-.176-1.002-.264-1.626-.264-.576 0-1.092.098-1.548.294-.456.196-.812.476-1.068.84s-.384.786-.384 1.266c0 .624.194 1.14.582 1.548.388.408 1.042.752 1.962 1.032a6.1 6.1 0 01.936.36c.216.112.368.238.456.378.088.14.132.314.132.522 0 .32-.118.57-.354.75-.236.18-.558.27-.966.27-.4 0-.77-.068-1.11-.204-.34-.136-.67-.34-.99-.612l-.996 1.092c.376.36.826.648 1.35.864.524.216 1.126.324 1.806.324zM51.112 20v-8.316h-1.968V20h1.968zm7.052 0l.204-1.56h-3.24v-6.756H53.16V20h5.004zm4.46 0l2.316-8.316h-2.016l-1.488 6.78-1.584-6.78h-2.076l2.4 8.316h2.448zm8.528 0v-1.416H68.02V16.46h2.556v-1.38H68.02v-1.992h2.928l.204-1.404h-5.1V20h5.1zm3.68 0v-3.18h.768L77.304 20h2.22l-2.112-3.54c.552-.248.956-.55 1.212-.906s.384-.802.384-1.338c0-.856-.286-1.492-.858-1.908-.572-.416-1.426-.624-2.562-.624h-2.724V20h1.968zm.828-4.536h-.828v-2.4h.708c.488 0 .85.092 1.086.276.236.184.354.476.354.876 0 .432-.11.748-.33.948-.22.2-.55.3-.99.3z"
			/>
			<g
				fill="#FFF"
				stroke="#A5A5A5"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
				<path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
				<path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
			</g>
			<g
				fill="#FFF"
				stroke="#6A6A6A"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
				<path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
				<path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
			</g>
			<path
				fill="#6A6A6A"
				d="M95.131 11.131L98 10l-2.869-1.131L94 6l-1.131 2.869L90 10l2.869 1.131L94 14zm0 12L98 22l-2.869-1.131L94 18l-1.131 2.869L90 22l2.869 1.131L94 26zm9.566-5.434L109 16l-4.303-1.697L103 10l-1.697 4.303L97 16l4.303 1.697L103 22z"
			/>
		</g>
	</svg>
);

export default SilverBadge;
