function LoggedinUserIcon(props) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8 2.28125C9.0875 2.28125 9.96875 3.1625 9.96875 4.25C9.96875 5.3375 9.0875 6.21875 8 6.21875C6.9125 6.21875 6.03125 5.3375 6.03125 4.25C6.03125 3.1625 6.9125 2.28125 8 2.28125ZM8 10.7188C10.7844 10.7188 13.7188 12.0875 13.7188 12.6875V13.7188H2.28125V12.6875C2.28125 12.0875 5.21562 10.7188 8 10.7188ZM8 0.5C5.92812 0.5 4.25 2.17812 4.25 4.25C4.25 6.32188 5.92812 8 8 8C10.0719 8 11.75 6.32188 11.75 4.25C11.75 2.17812 10.0719 0.5 8 0.5ZM8 8.9375C5.49687 8.9375 0.5 10.1938 0.5 12.6875V14.5625C0.5 15.0781 0.921875 15.5 1.4375 15.5H14.5625C15.0781 15.5 15.5 15.0781 15.5 14.5625V12.6875C15.5 10.1938 10.5031 8.9375 8 8.9375Z"
				fill="#190A28"
			/>
		</svg>
	);
}
export default LoggedinUserIcon;
